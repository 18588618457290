window.jQuery(document).ready(function ($) {

    var loadButton = $(".more-button.load_more_posts");

    if (loadButton.length > 0) {
        loadButton.click(function () {
            var that = $(this);
            var page = that.data('page');
            var newPage = page + 1;
            var ajaxUrl = $(this).data('url');

            $.ajax({
                url: ajaxUrl,
                type: 'post',
                data: {
                    page: page,
                    action: 'load_more_de_projects'
                },
                error: function (response) {
                    console.log(response);
                },
                success: function (response) {
                    that.data('page', newPage);
                    $('.projects-list').append(response);
                    var project_index = $('.projects-item-for-load:last-child').data('index');
                    if (newPage === project_index) {
                        $('.load_more_posts').hide();
                    }
                    revealPosts();
                }
            })
        });
    }

    function revealPosts() {
        var posts = $('.animated-posts:not(.reveal)');
        var i = 0;
        setInterval(function () {

            if (i >= posts.length) return false;

            var el = posts[i];
            $(el).addClass('reveal');

            i++

        })
    }


});