window.jQuery(document).ready(function ($) {
    var originalTitle;

    var blinkTitle;

    var blinkLogicState = false;

    document.head = document.head || document.getElementsByTagName('head')[0];

    function changeFavicon(src) {
        var link = document.createElement('link'),
            oldLink = document.getElementById('dynamic-favicon');
        link.id = 'dynamic-favicon';
        link.rel = 'shortcut icon';
        link.href = src;
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
    }

    changeFavicon('/wp-content/uploads/sites/2/2019/06/favicon_blue.png');

    function StartBlinking(title) {
        originalTitle = document.title;
        blinkTitle = title;
        BlinkIteration();
    }

    function BlinkIteration() {
        if (blinkLogicState == false) {
            document.title = blinkTitle;
        } else {
            document.title = originalTitle;
        }

        blinkLogicState = !blinkLogicState;

        blinkHandler = setTimeout(BlinkIteration, 3000);
    }

    function StopBlinking() {
        if (blinkHandler) {
            clearTimeout(blinkHandler);
        }
        document.title = originalTitle;
    }

    $(window).on('focus', function () {
        StopBlinking();
        changeFavicon('/wp-content/uploads/sites/2/2019/06/favicon_blue.png');

    });

    $(window).on('blur', function () {
        StartBlinking('Welcome');
        changeFavicon('/wp-content/uploads/sites/2/2019/06/favicon_red.png')
    });


});