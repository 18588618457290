(function ($) {
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
     * get browser
     *
     * @returns {boolean|string}
     */
    var isIE = /*@cc_on!@*/false || !!document.documentMode
    var isEdge = !isIE && !!window.StyleMedia
    if (isIE || isEdge) {
        $('body').addClass('ie-br')
    }
    var getBrowser = function () {
        var sBrowser = false
        var sUsrAg = navigator.userAgent
        if (sUsrAg.indexOf('Chrome') > -1) {
            sBrowser = 'Google Chrome'
        } else if (sUsrAg.indexOf('Safari') > -1) {
            sBrowser = 'Apple Safari'
        } else if (sUsrAg.indexOf('Opera') > -1) {
            sBrowser = 'Opera'
        } else if (sUsrAg.indexOf('Firefox') > -1) {
            sBrowser = 'Mozilla Firefox'
        } else if (sUsrAg.indexOf('MSIE') > -1) {
            sBrowser = 'Microsoft Internet Explorer'
        }
        return sBrowser
    }
    /**
     * Detect Safari
     */
    if (getBrowser() == 'Apple Safari') {
        $('body').addClass('safari');
    }
    /**
     * Manage the body specific class according to scroll direction
     */
    var showHideScrollup = function () {
        var bodyTop = document.documentElement.scrollTop || document.body.scrollTop
        var headerHeight = $('.navigation-wrapper').height()
        $(window).on('scroll', function () {
            var currBodyTop = document.documentElement.scrollTop || document.body.scrollTop
            var scrollTopPos = $(document).scrollTop()
            var body = $('body')
            var downClass = 'go-down'
            var upClass = 'go-up'
            if (scrollTopPos > (headerHeight)) {
                $('.site-header').addClass('scrolled')
            } else if (scrollTopPos < 10) {
                $('.site-header').removeClass('scrolled')
            }
            if (currBodyTop > bodyTop) {
                body.addClass(downClass).removeClass(upClass)
            } else {
                body.addClass(upClass).removeClass(downClass)
            }
            bodyTop = currBodyTop
        })
    }

    $('.myspinner').show()

    $('.myspinner').fadeOut(300, function () {
        setTimeout(function () {
            $('.blog .container-inner').css('opacity', '1');
            $('.blog .cat-nav-hide').css('opacity', '1');
        }, 100)
    });
    $(document).ready(function () {

        function isScrolledIntoView(elem) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var elemTop = $(elem).offset().top;
            var elemBottom = elemTop + $(elem).height();
            return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
        }

        var popupsCount = {};

        function popUpShow(elem) {
            $(elem).each(function () {
                var dataId = $(this).data('id');

                if (isScrolledIntoView($(this))) {
                    $(".popup-message").each(function () {
                        var close = $(this).find('.message-close');
                        if (!close.data('clicked')) {
                            var popupID = $(this).data('id');
                            if (popupID === dataId) {
                                popupsCount[$(this).attr('data-id')] = true;
                                $(this).fadeIn(600).addClass('popup-message-active');

                            } else {
                                var countOfActivePopUp = Object.values(popupsCount).reduce((a, item) => a + (item === true ? 1 : 0), 0);
                                if (countOfActivePopUp === 1) {
                                    console.log('Only one PopUp is active');
                                } else {
                                    $(this).fadeOut(300).removeClass('popup-message-active');
                                }
                            }
                            close.on('click', function () {
                                popupsCount[$(this).closest('.popup-message').attr('data-id')] = false;
                                $(this).data('clicked', true);
                                $(this).closest('.popup-message').fadeOut(300).removeClass('popup-message-active');
                            })
                        }

                    });
                }
            })
        }


        var popUpNotification = $('.show-help');

        if (popUpNotification.length > 0) {
            $(window).scroll(function () {
                popUpShow('.show-help');
            });
        }


        window.replay = function () {
            var portfolioVideo = document.getElementById('video-2');
            portfolioVideo.pause();
            portfolioVideo.currentTime = '0';
            portfolioVideo.play();
        };

        $('.slider_content').slick({
            autoplay: true,
            autoplaySpeed: 4500,
            infinite: true,
            verticalSwiping: false,
            vertical: false,
            dots: true,
            dotsClass: "slide-dots",
            arrows: true
        });

        if ($('.site-main.klekt').length) {

            $('.slider_full').slick({
                autoplay: false,
                infinite: true,
                verticalSwiping: false,
                vertical: false,
                dots: false,
                arrows: false,
                asNavFor: '#section-2 .section-list'
            });

            $('#section-2 .section-list').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: '.slider_full',
                dots: true,
                focusOnSelect: true
            })

            var slides = $('#section-2 .section-list .slick-slide')
            slides.removeClass('active')

            slides.eq(0).addClass('active')

            // On before slide change match active thumbnail to current slide
            $('#section-2 .slider_full').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                slides.removeClass('active')
                slides.eq(nextSlide).addClass('active')
            })
        }

        showHideScrollup()
        $('.contact-email input, .contact-message textarea').focus(function () {
            $(this).closest('.contact-email, .contact-message').addClass('active')
            /*$('#site-navigation').addClass('hideNav')*/
        })
        $('.contact-email input, .contact-message textarea').focusout(function () {
            $(this).closest('.contact-email, .contact-message').removeClass('active')
            /*$('#site-navigation').removeClass('hideNav')*/
        })
        // Post gallery masonry
        var gallery = $('.post-gallery .grid')

        function postGallery() {
            var msnry = new window.Masonry('.grid', {
                itemSelector: '.grid-item',
                columnWidth: '.grid-sizer',
                isFitWidth: true,
                gutter: 30
            })
            if (window.matchMedia('(max-width: 767px)').matches) {
                msnry.destroy()
            }
        }

        if (gallery.length) {
            postGallery()
            // lightbox options
            window.lightbox.option({
                'albumLabel': '%1 / %2',
                'resizeDuration': 200,
                'disableScrolling': true
            })
        }
        //copy link on share post button
        var copy = $('.copy-link')

        function copyTextToClipboard(text) {
            var textArea = document.createElement('textarea')
            // Place in top-left corner of screen regardless of scroll position.
            textArea.style.position = 'fixed'
            textArea.style.top = 0
            textArea.style.left = 0
            // Ensure it has a small width and height. Setting to 1px / 1em
            // doesn't work as this gives a negative w/h on some browsers.
            textArea.style.width = '2em'
            textArea.style.height = '2em'
            // We don't need padding, reducing the size if it does flash render.
            textArea.style.padding = 0
            // Clean up any borders.
            textArea.style.border = 'none'
            textArea.style.outline = 'none'
            textArea.style.boxShadow = 'none'
            // Avoid flash of white box if rendered for any reason.
            textArea.style.background = 'transparent'
            textArea.value = text
            document.body.appendChild(textArea)
            textArea.select()
            try {
                var successful = document.execCommand('copy')
                var msg = successful ? 'successful' : 'unsuccessful'
                console.log('Copying text command was ' + msg)
            } catch (err) {
                console.log('Oops, unable to copy')
            }
            document.body.removeChild(textArea)
        }

        function CopyLink() {
            copyTextToClipboard(location.href)
        }

        if (copy.length) {
            $('.copy-link-wrapper').on('click', '.copy-link', function (e) {
                CopyLink()
                $('.msg-wrap').fadeIn('fast', function () {
                    setTimeout(function () {
                        $('.msg-wrap').fadeOut(400, function () {
                        })
                    }, 500)
                })
            })
        }
        // related posts matchheight
        // var relatedPosts = $('.related-posts-section')
        // if (relatedPosts.length) {
        //   $('.entry-header').matchHeight()
        //   $('.entry-content').matchHeight()
        // }
        //css style 'object-fit' hack for IE and EDGE
        objectFitImages('img.object-fit-cover')

        // "Go top" button
        var goTop = $('.go-top')
        var windowH = $(window).height()
        var screenCounter = windowH * 3
        var footerOffset = $('.site-footer').offset().top
        var arrowBottomPosPx = goTop.css('bottom')
        var arrowBottomPos = parseInt(arrowBottomPosPx.slice(0, -2), 10)
        var arrowOffset = goTop.height() + arrowBottomPos
        var arrowStopPos = footerOffset - windowH
        var currScroll

        function updateVariables() {
            windowH = $(window).height()
            screenCounter = windowH * 3
            footerOffset = $('.site-footer').offset().top
            arrowBottomPosPx = goTop.css('bottom')
            arrowBottomPos = parseInt(arrowBottomPosPx.slice(0, -2), 10)
            arrowOffset = goTop.height() + arrowBottomPos
            arrowStopPos = footerOffset - windowH
        }

        $(window).on('resize', function () {
            updateVariables()
        })

        goTop.on('click', function (e) {
            e.preventDefault()
            $('html, body').animate({scrollTop: 0}, 800)
            return false
        })

        function goTopBtn() {
            currScroll = $(document).scrollTop()
            // console.log('Scroll: ' + currScroll)
            // console.log('WH: ' + windowH)
            // console.log('SC: ' + screenCounter)
            // console.log('ArrowOffset: ' + arrowOffset)
            // console.log('footerOffset: ' + footerOffset)
            // console.log('ArrowStopPos: ' + arrowStopPos)


            // if (( !$('body').hasClass('page-template-page-blog') ) && ( !$('body').hasClass('home') )) {
            // if (currScroll > arrowStopPos) {
            //   goTop.addClass('fixed').css('top', footerOffset - arrowOffset)
            // } else {
            //   goTop.removeClass('fixed').css('top', '')
            // }
            // }


            if (screenCounter < currScroll) {
                if (!goTop.hasClass('visible')) {
                    goTop.addClass('visible')
                    goTop.fadeIn(100)

                }
            } else {
                goTop.removeClass('visible')
                goTop.fadeOut(100)
            }
        }

        $(window).on('scroll', function () {
            goTopBtn()
        })

        // $(window).on('load', function () {
        var testimonialSlider = $('.testimonials-section .slickslider .slides')
        if (testimonialSlider.length) {
            testimonialSlider.slick({
                dots: true,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            arrows: false
                        }
                    }

                ]
            })
        }
        var relatePostSlider = $('.real-posts .real-slider .slides')
        if (relatePostSlider.length) {
            relatePostSlider.slick({
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            arrows: false,
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 560,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false
                        }
                    }
                ]
            })
        }
        // })
        // Page about timeline
        var timeline = $('.timeline-wrapper')
        if (timeline.length) {

            $('.nav-item').on('click', function () {
                $('.y-show').removeClass('y-show active')
                $(this).addClass('y-show')
            })


            $('.experience-list').slick({
                slidesToShow: 7,
                slidesToScroll: 1,
                infinite: false,
                draggable: false,
                focusOnSelect: true,
                prevArrow: $('.experience-prev'),
                nextArrow: $('.experience-next'),
                responsive: [
                    {
                        breakpoint: 1380,
                        settings: {
                            slidesToShow: 6,
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 5,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 4,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                ]
            });
        }


        // Page about events
        var events = $('.events-section .events-masonry')

        function eventsGallery() {
            var msnry = new window.Masonry('.events-masonry', {
                itemSelector: '.event-item',
                columnWidth: '.event-item-sizer',
                isFitWidth: true,
                gutter: 30
            })
            if (window.matchMedia('(max-width: 767px)').matches) {
                msnry.destroy()
            }
        }

        if (events.length) {
            eventsGallery()
        }
        var pageBlog = $('section.blog, .single-post')
        if (pageBlog.length) {

            // Blog container width
            var blogContainerW
            var updatedBlogContainerW
            var blogArticleW
            var numOfArticles
            // var blogArticleMaxW
            // var magicNumber

            function calcBlogContainerW() {

                blogContainerW = $('.blog .container').width() + 15 //magic scrollbar 15 px
                //console.log(blogContainerW)

                // blogArticleW = $('.blog .article-blog').width()
                blogArticleW = $('.blog .article-blog').outerWidth()
                // console.log(blogArticleW)
                // blogArticleMaxW = $('.blog .article-blog').css('max-width')
                // magicNumber = blogArticleMaxW.slice(0, -2)
                // magicNumber = 450 // Magic number / article max width
                numOfArticles = Math.floor(blogContainerW / blogArticleW)
                if (blogContainerW > blogArticleW) {
                    updatedBlogContainerW = (numOfArticles * blogArticleW)
                } else {
                    updatedBlogContainerW = '100%'
                }

                // console.log( 'Article Width: ' + blogArticleW )
                // console.log( 'Container Width: ' + blogContainerW )
                // console.log( 'Number of Articlesh: ' + numOfArticles )
                // console.log( 'Updated Width: ' + updatedBlogContainerW )

                return updatedBlogContainerW
            }

            // Use Match Height in Safari browser
            var articleChildH
            var articleParentH
            var articleCounter = 1
            var blogTiles = $('.blog .article-blog')
            var relatedPosts = $('.single-post .article-blog')

            function safariBlockHeight(selector) {
                if ($('body').hasClass('safari')) {
                    // selector.addClass('col-xl-3')

                    selector.find('.blog-post-container').each(function () {
                        $(this).css('height', 'auto')
                    })
                    selector.find('.blog-post-container').each(function () {
                        articleChildH = $(this).outerHeight()
                        articleParentH = $(this).closest('.article-blog').outerHeight()
                        // console.log( 'Article ' + articleCounter + ': ' + articleChildH + '/' + articleParentH )
                        articleCounter++
                        if (articleChildH < articleParentH) {
                            $(this).outerHeight(articleParentH)
                        }
                    })
                }
            }

            setTimeout(function () {
                safariBlockHeight(blogTiles)
                safariBlockHeight(relatedPosts)
            }, 300)

            // $('.blog .container-inner').innerWidth(calcBlogContainerW())
            $('.blog .container-inner').css('width', calcBlogContainerW)

            $(window).on('orientationchange', function () {
                // $('.blog .blog-post-container').removeAttr("style")
                safariBlockHeight(blogTiles)
                safariBlockHeight(relatedPosts)

                // if (pageBlog.length) {
                //   $('.blog .container-inner').width(calcBlogContainerW())
                //   // console.log( 'Orientation changed' )
                //
                //   safariBlockHeight()
                // }
            })
        }

        var doit
        $(window).on('resize', function () {
            if (pageBlog.length) {
                // $('.blog .container-inner').innerWidth(calcBlogContainerW())
                $('.blog .container-inner').css('width', calcBlogContainerW())

                safariBlockHeight(blogTiles)
                safariBlockHeight(relatedPosts)
            }

            if (gallery.length) {
                clearTimeout(doit)
                doit = setTimeout(postGallery, 500)
            }
            if (events.length) {
                clearTimeout(doit)
                doit = setTimeout(eventsGallery, 700)
            }

        })
        var valCompanyForm = $('.subscribe-form-wrapp #subForm')
        if (valCompanyForm.length) {
            valCompanyForm.validate()
        }
        var valFootLatter = $('#formSub')
        if (valFootLatter.length) {
            valFootLatter.validate()
        }
        // var valCommentform = $('#commentform')
        // if (valCommentform.length) {
        //   valCommentform.validate()
        // }
        var valCompanyform = $('#857')
        if (valCompanyform.length) {
            valCompanyform.validate()
        }
        var postCommentForm = $('.post-comment-form #commentform')
        if (postCommentForm.length) {
            postCommentForm.validate({
                rules: {
                    email: {
                        required: true,
                        email: true
                    }
                }
            })

        }
        // var postCommentFormEmail = $('#commentform #email')
        // if (postCommentFormEmail.length) {
        //   postCommentFormEmail.validate({
        //     rules: {
        //       email: {
        //         required: true,
        //         email: true
        //       }
        //     }
        //   })
        // }
        $('.wpcf7-form-control-wrap, .wpcf7-textarea').click(function () {
            $('.wpcf7-not-valid-tip', this).hide()
        })
        $('.disabled a, .disabled ').on('click', function (e) {
            e.preventDefault()
        })
        $('.easy-scroll').click(function (e) {
            e.preventDefault()
            $('html, body').animate({scrollTop: $('#main').position().top}, 1500)
        })

        $('.blockchain-scroll').click(function (e) {
            e.preventDefault()
            $('html, body').animate({scrollTop: $('#what').position().top - 100}, 1500)
        })

        var homePage = $('body.home')
        if (homePage.length) {
            var videoBg = $('.home #bgvid')

            if (videoBg.length > 0) {
                videoBg.get(0).play()
            }
            $(window).on('scroll', function () {
                var contentTop = $('.home #content').offset().top
                var windowSroll = $(this).scrollTop()
                var siteHeader = $('.site-header')
                if (windowSroll > contentTop) {
                    if (!siteHeader.hasClass('videofix')) {
                        siteHeader.addClass('videofix')
                        if (videoBg.length > 0) {
                            videoBg.get(0).pause()
                        }
                    }
                } else {
                    if (siteHeader.hasClass('videofix')) {
                        siteHeader.removeClass('videofix')
                        if (videoBg.length > 0) {
                            videoBg.get(0).play()
                        }
                    }
                }
            })
            if (window.innerWidth > 992) {
                animationsSrc = $.parseJSON(animationsSrc)
            } else {
                animationsSrc = $.parseJSON(animationsSrcMobile)
            }
            var projectImages = $('.project-image')
            var arrayLength = animationsSrc.length
            var index = 0;

            function getImage(index, src, projectImages, arrayLength) {
                $.get(src.index)
                    .done(function () {
                        $(projectImages[index]).attr('src', src[index])
                        index++
                        if (index < arrayLength) {
                            getImage(index, animationsSrc, projectImages, arrayLength)
                        }
                    })
            }

            getImage(index, animationsSrc, projectImages, arrayLength)
        }
        var accordion = $('#map-item')
        var toggleBtn = $('.toggle-btn')

        if (accordion.length) {
            $('.item-title').on('click', function () {
                var arrowToggle = $(this)
                arrowToggle.find(toggleBtn).toggleClass('active-up')
                arrowToggle.parent().find('.item-description').slideToggle(300)
            })
        }
        var uploadElement = $('.wpcf7-file')

        if (uploadElement.length) {
            var myString = 'Uploaded'
            var cleanbtn = $('.clear-btn')

            uploadElement.click(function () {
                uploadElement.change(function () {
                    var uploadElementName = $(this).val().split(/\\|\//).pop();
                    if ($(this).closest('label').contents().first().length) {
                        $(this).closest('label').contents().first()[0].textContent = uploadElementName;
                    }
                    $(this).closest('label').find('span:not(".wpcf7-form-control-wrap")').text(uploadElementName);
                    $('.pls-hold').html(myString)
                    cleanbtn.css('display', 'block')
                    cleanbtn.on('click', function (e) {
                        e.preventDefault()
                        cleanbtn.css('display', 'none')
                        var myString = 'Upload CV';
                        $('.pls-hold').html(myString)
                        $('.wpcf7-file').val('')
                    })
                })
            })
        }

        var uploadElementBl = $('.file-attach')

        if (uploadElementBl.length) {
            var stringBl = 'Uploaded'
            var cleanbtnBl = $('.clear-btn')

            uploadElementBl.click(function () {
                uploadElementBl.change(function () {
                    $('.pls-hold').html(stringBl)
                    cleanbtnBl.css('display', 'block')
                    cleanbtnBl.on('click', function (e) {
                        cleanbtnBl.css('display', 'none')
                        e.preventDefault()
                        var stringBl = 'Attach file';
                        $('.file-attach').val('')
                        $('.pls-hold').html(stringBl)
                    })
                })
            })
        }

        /* Makes standard height for textarea field - 22px
           And changes placeholder in the contact us form (file field)
           reset form after submitting
           */
        if ($('.wpcf7').length > 0) {
            var wpcf7Form = document.querySelector('.wpcf7');
            wpcf7Form.addEventListener('wpcf7submit', function (event) {
                $(this).find('textarea').height(22)
                var stringRpl = 'Attachments';
                var stringCvRpl = 'Upload CV';
                $('.contact-upload-file-plc').html(stringRpl)
                $('.pls-hold.upload-cv-plc').html(stringCvRpl)
                $('.clear-btn').css('display', 'none')
            }, false);
        }

        $(function () {
            $('#comment').on('keyup paste', function () {
                var $el = $(this),
                    offset = $el.innerHeight() - $el.height();

                if ($el.innerHeight < this.scrollHeight) {
                    //Grow the field if scroll height is smaller
                    $el.height(this.scrollHeight - offset);
                } else {
                    //Shrink the field and then re-set it to the scroll height in case it needs to shrink
                    $el.height(1);
                    $el.height(this.scrollHeight - offset);
                }
            });
        });

        $('#catapult-cookie-bar').hide().addClass('v-visible');
        $('#catapultCookie').click(function () {
            $('#catapult-cookie-bar').removeClass('v-visible');
        })

        $('.page-id-4 .project-permalink, .page-id-4 .project-image').click(function (e) {
            e.preventDefault();
        });
        $('.page-id-4 .project-permalink').addClass('disabled-project-permalink');


        autosize(document.querySelectorAll('textarea'));


        //Blockchain Tabs

        function tabStateActive(pos) {
            var before = $(".trigger").show();
            before.css({
                position: "absolute",
                top: pos.top + "px",
                left: pos.left + "px",
            }).show();
        }

        if ($(".tabs-list-nav li").length > 0) {
            $(".tabs-list-nav li").click(function () {
                var pos = $(this).position();
                tabStateActive(pos);
            });

            $(window).resize(function () {
                    var pos = $(".tabs-list-nav li.active").position();
                    tabStateActive(pos);
                }
            );
        }


        //Blockchain Canvas Animation
        var pJS = function (tag_id, params) {

            var canvas_el = document.querySelector('#' + tag_id + ' > .particles-js-canvas-el');

            /* particles.js variables with default values */
            this.pJS = {
                canvas: {
                    el: canvas_el,
                    w: canvas_el.offsetWidth,
                    h: canvas_el.offsetHeight
                },
                particles: {
                    array: []
                },
                interactivity: {
                    detect_on: 'canvas',
                    events: {
                        onhover: {
                            enable: true,
                            mode: 'grab'
                        },
                        onclick: {
                            enable: true,
                            mode: 'push'
                        },
                        resize: true
                    },
                    modes: {
                        grab: {
                            distance: 100,
                            line_linked: {
                                opacity: 1
                            }
                        },
                        bubble: {
                            distance: 200,
                            size: 80,
                            duration: 0.4
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4
                        },
                        push: {
                            particles_nb: 4
                        },
                        remove: {
                            particles_nb: 2
                        }
                    },
                    mouse: {}
                },
                retina_detect: false,
                fn: {
                    interact: {},
                    modes: {},
                    vendors: {}
                },
                tmp: {}
            };

            var pJS = this.pJS;

            /* params settings */
            if (params) {
                Object.deepExtend(pJS, params);
            }

            pJS.tmp.obj = {
                size_value: pJS.particles.size.value,
                size_anim_speed: pJS.particles.size.anim.speed,
                move_speed: pJS.particles.move.speed,
                line_linked_distance: pJS.particles.line_linked.distance,
                line_linked_width: pJS.particles.line_linked.width,
                mode_grab_distance: pJS.interactivity.modes.grab.distance,
                mode_bubble_distance: pJS.interactivity.modes.bubble.distance,
                mode_bubble_size: pJS.interactivity.modes.bubble.size,
                mode_repulse_distance: pJS.interactivity.modes.repulse.distance
            };

            pJS.fn.retinaInit = function () {

                if (pJS.retina_detect && window.devicePixelRatio > 1) {
                    pJS.canvas.pxratio = window.devicePixelRatio;
                    pJS.tmp.retina = true;
                } else {
                    pJS.canvas.pxratio = 1;
                    pJS.tmp.retina = false;
                }

                pJS.canvas.w = pJS.canvas.el.offsetWidth * pJS.canvas.pxratio;
                pJS.canvas.h = pJS.canvas.el.offsetHeight * pJS.canvas.pxratio;

                pJS.particles.size.value = pJS.tmp.obj.size_value * pJS.canvas.pxratio;
                pJS.particles.size.anim.speed = pJS.tmp.obj.size_anim_speed * pJS.canvas.pxratio;
                pJS.particles.move.speed = pJS.tmp.obj.move_speed * pJS.canvas.pxratio;
                pJS.particles.line_linked.distance = pJS.tmp.obj.line_linked_distance * pJS.canvas.pxratio;
                pJS.interactivity.modes.grab.distance = pJS.tmp.obj.mode_grab_distance * pJS.canvas.pxratio;
                pJS.interactivity.modes.bubble.distance = pJS.tmp.obj.mode_bubble_distance * pJS.canvas.pxratio;
                pJS.particles.line_linked.width = pJS.tmp.obj.line_linked_width * pJS.canvas.pxratio;
                pJS.interactivity.modes.bubble.size = pJS.tmp.obj.mode_bubble_size * pJS.canvas.pxratio;
                pJS.interactivity.modes.repulse.distance = pJS.tmp.obj.mode_repulse_distance * pJS.canvas.pxratio;

            };

            /* ---------- pJS functions - canvas ------------ */

            pJS.fn.canvasInit = function () {
                pJS.canvas.ctx = pJS.canvas.el.getContext('2d');
            };

            pJS.fn.canvasSize = function () {

                pJS.canvas.el.width = pJS.canvas.w;
                pJS.canvas.el.height = pJS.canvas.h;

                if (pJS && pJS.interactivity.events.resize) {

                    window.addEventListener('resize', function () {

                        pJS.canvas.w = pJS.canvas.el.offsetWidth;
                        pJS.canvas.h = pJS.canvas.el.offsetHeight;

                        /* resize canvas */
                        if (pJS.tmp.retina) {
                            pJS.canvas.w *= pJS.canvas.pxratio;
                            pJS.canvas.h *= pJS.canvas.pxratio;
                        }

                        pJS.canvas.el.width = pJS.canvas.w;
                        pJS.canvas.el.height = pJS.canvas.h;

                        /* repaint canvas on anim disabled */
                        if (!pJS.particles.move.enable) {
                            pJS.fn.particlesEmpty();
                            pJS.fn.particlesCreate();
                            pJS.fn.particlesDraw();
                            pJS.fn.vendors.densityAutoParticles();
                        }

                        /* density particles enabled */
                        pJS.fn.vendors.densityAutoParticles();

                    });

                }

            };


            pJS.fn.canvasPaint = function () {
                pJS.canvas.ctx.fillRect(0, 0, pJS.canvas.w, pJS.canvas.h);
            };

            pJS.fn.canvasClear = function () {
                pJS.canvas.ctx.clearRect(0, 0, pJS.canvas.w, pJS.canvas.h);
            };

            /* --------- pJS functions - particles ----------- */

            pJS.fn.particle = function (color, opacity, position) {

                /* size */
                this.radius = (pJS.particles.size.random ? Math.random() : 1) * pJS.particles.size.value;
                if (pJS.particles.size.anim.enable) {
                    this.size_status = false;
                    this.vs = pJS.particles.size.anim.speed / 100;
                    if (!pJS.particles.size.anim.sync) {
                        this.vs = this.vs * Math.random();
                    }
                }

                /* position */
                this.x = position ? position.x : Math.random() * pJS.canvas.w;
                this.y = position ? position.y : Math.random() * pJS.canvas.h;

                /* check position  - into the canvas */
                if (this.x > pJS.canvas.w - this.radius * 2) this.x = this.x - this.radius;
                else if (this.x < this.radius * 2) this.x = this.x + this.radius;
                if (this.y > pJS.canvas.h - this.radius * 2) this.y = this.y - this.radius;
                else if (this.y < this.radius * 2) this.y = this.y + this.radius;

                /* check position - avoid overlap */
                if (pJS.particles.move.bounce) {
                    pJS.fn.vendors.checkOverlap(this, position);
                }

                /* color */
                this.color = {};
                if (typeof (color.value) == 'object') {

                    if (color.value instanceof Array) {
                        var color_selected = color.value[Math.floor(Math.random() * pJS.particles.color.value.length)];
                        this.color.rgb = hexToRgb(color_selected);
                    } else {
                        if (color.value.r != undefined && color.value.g != undefined && color.value.b != undefined) {
                            this.color.rgb = {
                                r: color.value.r,
                                g: color.value.g,
                                b: color.value.b
                            }
                        }
                        if (color.value.h != undefined && color.value.s != undefined && color.value.l != undefined) {
                            this.color.hsl = {
                                h: color.value.h,
                                s: color.value.s,
                                l: color.value.l
                            }
                        }
                    }

                } else if (color.value == 'random') {
                    this.color.rgb = {
                        r: (Math.floor(Math.random() * (255 - 0 + 1)) + 0),
                        g: (Math.floor(Math.random() * (255 - 0 + 1)) + 0),
                        b: (Math.floor(Math.random() * (255 - 0 + 1)) + 0)
                    }
                } else if (typeof (color.value) == 'string') {
                    this.color = color;
                    this.color.rgb = hexToRgb(this.color.value);
                }

                /* opacity */
                this.opacity = (pJS.particles.opacity.random ? Math.random() : 1) * pJS.particles.opacity.value;
                if (pJS.particles.opacity.anim.enable) {
                    this.opacity_status = false;
                    this.vo = pJS.particles.opacity.anim.speed / 100;
                    if (!pJS.particles.opacity.anim.sync) {
                        this.vo = this.vo * Math.random();
                    }
                }

                /* animation - velocity for speed */
                var velbase = {}
                switch (pJS.particles.move.direction) {
                    case 'top':
                        velbase = {x: 0, y: -1};
                        break;
                    case 'top-right':
                        velbase = {x: 0.5, y: -0.5};
                        break;
                    case 'right':
                        velbase = {x: 1, y: -0};
                        break;
                    case 'bottom-right':
                        velbase = {x: 0.5, y: 0.5};
                        break;
                    case 'bottom':
                        velbase = {x: 0, y: 1};
                        break;
                    case 'bottom-left':
                        velbase = {x: -0.5, y: 1};
                        break;
                    case 'left':
                        velbase = {x: -1, y: 0};
                        break;
                    case 'top-left':
                        velbase = {x: -0.5, y: -0.5};
                        break;
                    default:
                        velbase = {x: 0, y: 0};
                        break;
                }

                if (pJS.particles.move.straight) {
                    this.vx = velbase.x;
                    this.vy = velbase.y;
                    if (pJS.particles.move.random) {
                        this.vx = this.vx * (Math.random());
                        this.vy = this.vy * (Math.random());
                    }
                } else {
                    this.vx = velbase.x + Math.random() - 0.5;
                    this.vy = velbase.y + Math.random() - 0.5;
                }

                // var theta = 2.0 * Math.PI * Math.random();
                // this.vx = Math.cos(theta);
                // this.vy = Math.sin(theta);

                this.vx_i = this.vx;
                this.vy_i = this.vy;


                /* if shape is image */

                var shape_type = pJS.particles.shape.type;
                if (typeof (shape_type) == 'object') {
                    if (shape_type instanceof Array) {
                        var shape_selected = shape_type[Math.floor(Math.random() * shape_type.length)];
                        this.shape = shape_selected;
                    }
                } else {
                    this.shape = shape_type;
                }

                if (this.shape == 'image') {
                    var sh = pJS.particles.shape;
                    this.img = {
                        src: sh.image.src,
                        ratio: sh.image.width / sh.image.height
                    }
                    if (!this.img.ratio) this.img.ratio = 1;
                    if (pJS.tmp.img_type == 'svg' && pJS.tmp.source_svg != undefined) {
                        pJS.fn.vendors.createSvgImg(this);
                        if (pJS.tmp.pushing) {
                            this.img.loaded = false;
                        }
                    }
                }


            };


            pJS.fn.particle.prototype.draw = function () {

                var p = this;

                if (p.radius_bubble != undefined) {
                    var radius = p.radius_bubble;
                } else {
                    var radius = p.radius;
                }

                if (p.opacity_bubble != undefined) {
                    var opacity = p.opacity_bubble;
                } else {
                    var opacity = p.opacity;
                }

                if (p.color.rgb) {
                    var color_value = 'rgba(' + p.color.rgb.r + ',' + p.color.rgb.g + ',' + p.color.rgb.b + ',' + opacity + ')';
                } else {
                    var color_value = 'hsla(' + p.color.hsl.h + ',' + p.color.hsl.s + '%,' + p.color.hsl.l + '%,' + opacity + ')';
                }

                pJS.canvas.ctx.fillStyle = color_value;
                pJS.canvas.ctx.beginPath();

                switch (p.shape) {

                    case 'circle':
                        pJS.canvas.ctx.arc(p.x, p.y, radius, 0, Math.PI * 2, false);
                        break;

                    case 'edge':
                        pJS.canvas.ctx.rect(p.x - radius, p.y - radius, radius * 2, radius * 2);
                        break;

                    case 'triangle':
                        pJS.fn.vendors.drawShape(pJS.canvas.ctx, p.x - radius, p.y + radius / 1.66, radius * 2, 3, 2);
                        break;

                    case 'polygon':
                        pJS.fn.vendors.drawShape(
                            pJS.canvas.ctx,
                            p.x - radius / (pJS.particles.shape.polygon.nb_sides / 3.5), // startX
                            p.y - radius / (2.66 / 3.5), // startY
                            radius * 2.66 / (pJS.particles.shape.polygon.nb_sides / 3), // sideLength
                            pJS.particles.shape.polygon.nb_sides, // sideCountNumerator
                            1 // sideCountDenominator
                        );
                        break;

                    case 'star':
                        pJS.fn.vendors.drawShape(
                            pJS.canvas.ctx,
                            p.x - radius * 2 / (pJS.particles.shape.polygon.nb_sides / 4), // startX
                            p.y - radius / (2 * 2.66 / 3.5), // startY
                            radius * 2 * 2.66 / (pJS.particles.shape.polygon.nb_sides / 3), // sideLength
                            pJS.particles.shape.polygon.nb_sides, // sideCountNumerator
                            2 // sideCountDenominator
                        );
                        break;

                    case 'image':

                    function draw() {
                        pJS.canvas.ctx.drawImage(
                            img_obj,
                            p.x - radius,
                            p.y - radius,
                            radius * 2,
                            radius * 2 / p.img.ratio
                        );
                    }

                        if (pJS.tmp.img_type == 'svg') {
                            var img_obj = p.img.obj;
                        } else {
                            var img_obj = pJS.tmp.img_obj;
                        }

                        if (img_obj) {
                            draw();
                        }

                        break;

                }

                pJS.canvas.ctx.closePath();

                if (pJS.particles.shape.stroke.width > 0) {
                    pJS.canvas.ctx.strokeStyle = pJS.particles.shape.stroke.color;
                    pJS.canvas.ctx.lineWidth = pJS.particles.shape.stroke.width;
                    pJS.canvas.ctx.stroke();
                }

                pJS.canvas.ctx.fill();

            };


            pJS.fn.particlesCreate = function () {
                for (var i = 0; i < pJS.particles.number.value; i++) {
                    pJS.particles.array.push(new pJS.fn.particle(pJS.particles.color, pJS.particles.opacity.value));
                }
            };

            pJS.fn.particlesUpdate = function () {

                for (var i = 0; i < pJS.particles.array.length; i++) {

                    /* the particle */
                    var p = pJS.particles.array[i];

                    // var d = ( dx = pJS.interactivity.mouse.click_pos_x - p.x ) * dx + ( dy = pJS.interactivity.mouse.click_pos_y - p.y ) * dy;
                    // var f = -BANG_SIZE / d;
                    // if ( d < BANG_SIZE ) {
                    //     var t = Math.atan2( dy, dx );
                    //     p.vx = f * Math.cos(t);
                    //     p.vy = f * Math.sin(t);
                    // }

                    /* move the particle */
                    if (pJS.particles.move.enable) {
                        var ms = pJS.particles.move.speed / 2;
                        p.x += p.vx * ms;
                        p.y += p.vy * ms;
                    }

                    /* change opacity status */
                    if (pJS.particles.opacity.anim.enable) {
                        if (p.opacity_status == true) {
                            if (p.opacity >= pJS.particles.opacity.value) p.opacity_status = false;
                            p.opacity += p.vo;
                        } else {
                            if (p.opacity <= pJS.particles.opacity.anim.opacity_min) p.opacity_status = true;
                            p.opacity -= p.vo;
                        }
                        if (p.opacity < 0) p.opacity = 0;
                    }

                    /* change size */
                    if (pJS.particles.size.anim.enable) {
                        if (p.size_status == true) {
                            if (p.radius >= pJS.particles.size.value) p.size_status = false;
                            p.radius += p.vs;
                        } else {
                            if (p.radius <= pJS.particles.size.anim.size_min) p.size_status = true;
                            p.radius -= p.vs;
                        }
                        if (p.radius < 0) p.radius = 0;
                    }

                    /* change particle position if it is out of canvas */
                    if (pJS.particles.move.out_mode == 'bounce') {
                        var new_pos = {
                            x_left: p.radius,
                            x_right: pJS.canvas.w,
                            y_top: p.radius,
                            y_bottom: pJS.canvas.h
                        }
                    } else {
                        var new_pos = {
                            x_left: -p.radius,
                            x_right: pJS.canvas.w + p.radius,
                            y_top: -p.radius,
                            y_bottom: pJS.canvas.h + p.radius
                        }
                    }

                    if (p.x - p.radius > pJS.canvas.w) {
                        p.x = new_pos.x_left;
                        p.y = Math.random() * pJS.canvas.h;
                    } else if (p.x + p.radius < 0) {
                        p.x = new_pos.x_right;
                        p.y = Math.random() * pJS.canvas.h;
                    }
                    if (p.y - p.radius > pJS.canvas.h) {
                        p.y = new_pos.y_top;
                        p.x = Math.random() * pJS.canvas.w;
                    } else if (p.y + p.radius < 0) {
                        p.y = new_pos.y_bottom;
                        p.x = Math.random() * pJS.canvas.w;
                    }

                    /* out of canvas modes */
                    switch (pJS.particles.move.out_mode) {
                        case 'bounce':
                            if (p.x + p.radius > pJS.canvas.w) p.vx = -p.vx;
                            else if (p.x - p.radius < 0) p.vx = -p.vx;
                            if (p.y + p.radius > pJS.canvas.h) p.vy = -p.vy;
                            else if (p.y - p.radius < 0) p.vy = -p.vy;
                            break;
                    }

                    /* events */
                    if (isInArray('grab', pJS.interactivity.events.onhover.mode)) {
                        pJS.fn.modes.grabParticle(p);
                    }

                    if (isInArray('bubble', pJS.interactivity.events.onhover.mode) || isInArray('bubble', pJS.interactivity.events.onclick.mode)) {
                        pJS.fn.modes.bubbleParticle(p);
                    }

                    if (isInArray('repulse', pJS.interactivity.events.onhover.mode) || isInArray('repulse', pJS.interactivity.events.onclick.mode)) {
                        pJS.fn.modes.repulseParticle(p);
                    }

                    /* interaction auto between particles */
                    if (pJS.particles.line_linked.enable || pJS.particles.move.attract.enable) {
                        for (var j = i + 1; j < pJS.particles.array.length; j++) {
                            var p2 = pJS.particles.array[j];

                            /* link particles */
                            if (pJS.particles.line_linked.enable) {
                                pJS.fn.interact.linkParticles(p, p2);
                            }

                            /* attract particles */
                            if (pJS.particles.move.attract.enable) {
                                pJS.fn.interact.attractParticles(p, p2);
                            }

                            /* bounce particles */
                            if (pJS.particles.move.bounce) {
                                pJS.fn.interact.bounceParticles(p, p2);
                            }

                        }
                    }


                }

            };

            pJS.fn.particlesDraw = function () {

                /* clear canvas */
                pJS.canvas.ctx.clearRect(0, 0, pJS.canvas.w, pJS.canvas.h);

                /* update each particles param */
                pJS.fn.particlesUpdate();

                /* draw each particle */
                for (var i = 0; i < pJS.particles.array.length; i++) {
                    var p = pJS.particles.array[i];
                    p.draw();
                }

            };

            pJS.fn.particlesEmpty = function () {
                pJS.particles.array = [];
            };

            pJS.fn.particlesRefresh = function () {

                /* init all */
                cancelRequestAnimFrame(pJS.fn.checkAnimFrame);
                cancelRequestAnimFrame(pJS.fn.drawAnimFrame);
                pJS.tmp.source_svg = undefined;
                pJS.tmp.img_obj = undefined;
                pJS.tmp.count_svg = 0;
                pJS.fn.particlesEmpty();
                pJS.fn.canvasClear();

                /* restart */
                pJS.fn.vendors.start();

            };


            /* ---------- pJS functions - particles interaction ------------ */

            pJS.fn.interact.linkParticles = function (p1, p2) {

                var dx = p1.x - p2.x,
                    dy = p1.y - p2.y,
                    dist = Math.sqrt(dx * dx + dy * dy);

                /* draw a line between p1 and p2 if the distance between them is under the config distance */
                if (dist <= pJS.particles.line_linked.distance) {

                    var opacity_line = pJS.particles.line_linked.opacity - (dist / (1 / pJS.particles.line_linked.opacity)) / pJS.particles.line_linked.distance;

                    if (opacity_line > 0) {

                        /* style */
                        var color_line = pJS.particles.line_linked.color_rgb_line;
                        pJS.canvas.ctx.strokeStyle = 'rgba(' + color_line.r + ',' + color_line.g + ',' + color_line.b + ',' + opacity_line + ')';
                        pJS.canvas.ctx.lineWidth = pJS.particles.line_linked.width;
                        //pJS.canvas.ctx.lineCap = 'round'; /* performance issue */

                        /* path */
                        pJS.canvas.ctx.beginPath();
                        pJS.canvas.ctx.moveTo(p1.x, p1.y);
                        pJS.canvas.ctx.lineTo(p2.x, p2.y);
                        pJS.canvas.ctx.stroke();
                        pJS.canvas.ctx.closePath();

                    }

                }

            };


            pJS.fn.interact.attractParticles = function (p1, p2) {

                /* condensed particles */
                var dx = p1.x - p2.x,
                    dy = p1.y - p2.y,
                    dist = Math.sqrt(dx * dx + dy * dy);

                if (dist <= pJS.particles.line_linked.distance) {

                    var ax = dx / (pJS.particles.move.attract.rotateX * 1000),
                        ay = dy / (pJS.particles.move.attract.rotateY * 1000);

                    p1.vx -= ax;
                    p1.vy -= ay;

                    p2.vx += ax;
                    p2.vy += ay;

                }


            }


            pJS.fn.interact.bounceParticles = function (p1, p2) {

                var dx = p1.x - p2.x,
                    dy = p1.y - p2.y,
                    dist = Math.sqrt(dx * dx + dy * dy),
                    dist_p = p1.radius + p2.radius;

                if (dist <= dist_p) {
                    p1.vx = -p1.vx;
                    p1.vy = -p1.vy;

                    p2.vx = -p2.vx;
                    p2.vy = -p2.vy;
                }

            }


            /* ---------- pJS functions - modes events ------------ */

            pJS.fn.modes.pushParticles = function (nb, pos) {

                pJS.tmp.pushing = true;

                for (var i = 0; i < nb; i++) {
                    pJS.particles.array.push(
                        new pJS.fn.particle(
                            pJS.particles.color,
                            pJS.particles.opacity.value,
                            {
                                'x': pos ? pos.pos_x : Math.random() * pJS.canvas.w,
                                'y': pos ? pos.pos_y : Math.random() * pJS.canvas.h
                            }
                        )
                    )
                    if (i == nb - 1) {
                        if (!pJS.particles.move.enable) {
                            pJS.fn.particlesDraw();
                        }
                        pJS.tmp.pushing = false;
                    }
                }

            };


            pJS.fn.modes.removeParticles = function (nb) {

                pJS.particles.array.splice(0, nb);
                if (!pJS.particles.move.enable) {
                    pJS.fn.particlesDraw();
                }

            };


            pJS.fn.modes.bubbleParticle = function (p) {

                /* on hover event */
                if (pJS.interactivity.events.onhover.enable && isInArray('bubble', pJS.interactivity.events.onhover.mode)) {

                    var dx_mouse = p.x - pJS.interactivity.mouse.pos_x,
                        dy_mouse = p.y - pJS.interactivity.mouse.pos_y,
                        dist_mouse = Math.sqrt(dx_mouse * dx_mouse + dy_mouse * dy_mouse),
                        ratio = 1 - dist_mouse / pJS.interactivity.modes.bubble.distance;

                    function init() {
                        p.opacity_bubble = p.opacity;
                        p.radius_bubble = p.radius;
                    }

                    /* mousemove - check ratio */
                    if (dist_mouse <= pJS.interactivity.modes.bubble.distance) {

                        if (ratio >= 0 && pJS.interactivity.status == 'mousemove') {

                            /* size */
                            if (pJS.interactivity.modes.bubble.size != pJS.particles.size.value) {

                                if (pJS.interactivity.modes.bubble.size > pJS.particles.size.value) {
                                    var size = p.radius + (pJS.interactivity.modes.bubble.size * ratio);
                                    if (size >= 0) {
                                        p.radius_bubble = size;
                                    }
                                } else {
                                    var dif = p.radius - pJS.interactivity.modes.bubble.size,
                                        size = p.radius - (dif * ratio);
                                    if (size > 0) {
                                        p.radius_bubble = size;
                                    } else {
                                        p.radius_bubble = 0;
                                    }
                                }

                            }

                            /* opacity */
                            if (pJS.interactivity.modes.bubble.opacity != pJS.particles.opacity.value) {

                                if (pJS.interactivity.modes.bubble.opacity > pJS.particles.opacity.value) {
                                    var opacity = pJS.interactivity.modes.bubble.opacity * ratio;
                                    if (opacity > p.opacity && opacity <= pJS.interactivity.modes.bubble.opacity) {
                                        p.opacity_bubble = opacity;
                                    }
                                } else {
                                    var opacity = p.opacity - (pJS.particles.opacity.value - pJS.interactivity.modes.bubble.opacity) * ratio;
                                    if (opacity < p.opacity && opacity >= pJS.interactivity.modes.bubble.opacity) {
                                        p.opacity_bubble = opacity;
                                    }
                                }

                            }

                        }

                    } else {
                        init();
                    }


                    /* mouseleave */
                    if (pJS.interactivity.status == 'mouseleave') {
                        init();
                    }

                }

                /* on click event */
                else if (pJS.interactivity.events.onclick.enable && isInArray('bubble', pJS.interactivity.events.onclick.mode)) {


                    if (pJS.tmp.bubble_clicking) {
                        var dx_mouse = p.x - pJS.interactivity.mouse.click_pos_x,
                            dy_mouse = p.y - pJS.interactivity.mouse.click_pos_y,
                            dist_mouse = Math.sqrt(dx_mouse * dx_mouse + dy_mouse * dy_mouse),
                            time_spent = (new Date().getTime() - pJS.interactivity.mouse.click_time) / 1000;

                        if (time_spent > pJS.interactivity.modes.bubble.duration) {
                            pJS.tmp.bubble_duration_end = true;
                        }

                        if (time_spent > pJS.interactivity.modes.bubble.duration * 2) {
                            pJS.tmp.bubble_clicking = false;
                            pJS.tmp.bubble_duration_end = false;
                        }
                    }


                    function process(bubble_param, particles_param, p_obj_bubble, p_obj, id) {

                        if (bubble_param != particles_param) {

                            if (!pJS.tmp.bubble_duration_end) {
                                if (dist_mouse <= pJS.interactivity.modes.bubble.distance) {
                                    if (p_obj_bubble != undefined) var obj = p_obj_bubble;
                                    else var obj = p_obj;
                                    if (obj != bubble_param) {
                                        var value = p_obj - (time_spent * (p_obj - bubble_param) / pJS.interactivity.modes.bubble.duration);
                                        if (id == 'size') p.radius_bubble = value;
                                        if (id == 'opacity') p.opacity_bubble = value;
                                    }
                                } else {
                                    if (id == 'size') p.radius_bubble = undefined;
                                    if (id == 'opacity') p.opacity_bubble = undefined;
                                }
                            } else {
                                if (p_obj_bubble != undefined) {
                                    var value_tmp = p_obj - (time_spent * (p_obj - bubble_param) / pJS.interactivity.modes.bubble.duration),
                                        dif = bubble_param - value_tmp;
                                    value = bubble_param + dif;
                                    if (id == 'size') p.radius_bubble = value;
                                    if (id == 'opacity') p.opacity_bubble = value;
                                }
                            }

                        }

                    }

                    if (pJS.tmp.bubble_clicking) {
                        /* size */
                        process(pJS.interactivity.modes.bubble.size, pJS.particles.size.value, p.radius_bubble, p.radius, 'size');
                        /* opacity */
                        process(pJS.interactivity.modes.bubble.opacity, pJS.particles.opacity.value, p.opacity_bubble, p.opacity, 'opacity');
                    }

                }

            };


            pJS.fn.modes.repulseParticle = function (p) {

                if (pJS.interactivity.events.onhover.enable && isInArray('repulse', pJS.interactivity.events.onhover.mode) && pJS.interactivity.status == 'mousemove') {

                    var dx_mouse = p.x - pJS.interactivity.mouse.pos_x,
                        dy_mouse = p.y - pJS.interactivity.mouse.pos_y,
                        dist_mouse = Math.sqrt(dx_mouse * dx_mouse + dy_mouse * dy_mouse);

                    var normVec = {x: dx_mouse / dist_mouse, y: dy_mouse / dist_mouse},
                        repulseRadius = pJS.interactivity.modes.repulse.distance,
                        velocity = 100,
                        repulseFactor = clamp((1 / repulseRadius) * (-1 * Math.pow(dist_mouse / repulseRadius, 2) + 1) * repulseRadius * velocity, 0, 50);

                    var pos = {
                        x: p.x + normVec.x * repulseFactor,
                        y: p.y + normVec.y * repulseFactor
                    }

                    if (pJS.particles.move.out_mode == 'bounce') {
                        if (pos.x - p.radius > 0 && pos.x + p.radius < pJS.canvas.w) p.x = pos.x;
                        if (pos.y - p.radius > 0 && pos.y + p.radius < pJS.canvas.h) p.y = pos.y;
                    } else {
                        p.x = pos.x;
                        p.y = pos.y;
                    }

                } else if (pJS.interactivity.events.onclick.enable && isInArray('repulse', pJS.interactivity.events.onclick.mode)) {

                    if (!pJS.tmp.repulse_finish) {
                        pJS.tmp.repulse_count++;
                        if (pJS.tmp.repulse_count == pJS.particles.array.length) {
                            pJS.tmp.repulse_finish = true;
                        }
                    }

                    if (pJS.tmp.repulse_clicking) {

                        var repulseRadius = Math.pow(pJS.interactivity.modes.repulse.distance / 6, 3);

                        var dx = pJS.interactivity.mouse.click_pos_x - p.x,
                            dy = pJS.interactivity.mouse.click_pos_y - p.y,
                            d = dx * dx + dy * dy;

                        var force = -repulseRadius / d * 1;

                        function process() {

                            var f = Math.atan2(dy, dx);
                            p.vx = force * Math.cos(f);
                            p.vy = force * Math.sin(f);

                            if (pJS.particles.move.out_mode == 'bounce') {
                                var pos = {
                                    x: p.x + p.vx,
                                    y: p.y + p.vy
                                }
                                if (pos.x + p.radius > pJS.canvas.w) p.vx = -p.vx;
                                else if (pos.x - p.radius < 0) p.vx = -p.vx;
                                if (pos.y + p.radius > pJS.canvas.h) p.vy = -p.vy;
                                else if (pos.y - p.radius < 0) p.vy = -p.vy;
                            }

                        }

                        // default
                        if (d <= repulseRadius) {
                            process();
                        }

                        // bang - slow motion mode
                        // if(!pJS.tmp.repulse_finish){
                        //   if(d <= repulseRadius){
                        //     process();
                        //   }
                        // }else{
                        //   process();
                        // }


                    } else {

                        if (pJS.tmp.repulse_clicking == false) {

                            p.vx = p.vx_i;
                            p.vy = p.vy_i;

                        }

                    }

                }

            }


            pJS.fn.modes.grabParticle = function (p) {

                if (pJS.interactivity.events.onhover.enable && pJS.interactivity.status == 'mousemove') {

                    var dx_mouse = p.x - pJS.interactivity.mouse.pos_x,
                        dy_mouse = p.y - pJS.interactivity.mouse.pos_y,
                        dist_mouse = Math.sqrt(dx_mouse * dx_mouse + dy_mouse * dy_mouse);

                    /* draw a line between the cursor and the particle if the distance between them is under the config distance */
                    if (dist_mouse <= pJS.interactivity.modes.grab.distance) {

                        var opacity_line = pJS.interactivity.modes.grab.line_linked.opacity - (dist_mouse / (1 / pJS.interactivity.modes.grab.line_linked.opacity)) / pJS.interactivity.modes.grab.distance;

                        if (opacity_line > 0) {

                            /* style */
                            var color_line = pJS.particles.line_linked.color_rgb_line;
                            pJS.canvas.ctx.strokeStyle = 'rgba(' + color_line.r + ',' + color_line.g + ',' + color_line.b + ',' + opacity_line + ')';
                            pJS.canvas.ctx.lineWidth = pJS.particles.line_linked.width;
                            //pJS.canvas.ctx.lineCap = 'round'; /* performance issue */

                            /* path */
                            pJS.canvas.ctx.beginPath();
                            pJS.canvas.ctx.moveTo(p.x, p.y);
                            pJS.canvas.ctx.lineTo(pJS.interactivity.mouse.pos_x, pJS.interactivity.mouse.pos_y);
                            pJS.canvas.ctx.stroke();
                            pJS.canvas.ctx.closePath();

                        }

                    }

                }

            };


            /* ---------- pJS functions - vendors ------------ */

            pJS.fn.vendors.eventsListeners = function () {

                /* events target element */
                if (pJS.interactivity.detect_on == 'window') {
                    pJS.interactivity.el = window;
                } else {
                    pJS.interactivity.el = pJS.canvas.el;
                }


                /* detect mouse pos - on hover / click event */
                if (pJS.interactivity.events.onhover.enable || pJS.interactivity.events.onclick.enable) {

                    /* el on mousemove */
                    pJS.interactivity.el.addEventListener('mousemove', function (e) {

                        if (pJS.interactivity.el == window) {
                            var pos_x = e.clientX,
                                pos_y = e.clientY;
                        } else {
                            var pos_x = e.offsetX || e.clientX,
                                pos_y = e.offsetY || e.clientY;
                        }

                        pJS.interactivity.mouse.pos_x = pos_x;
                        pJS.interactivity.mouse.pos_y = pos_y;

                        if (pJS.tmp.retina) {
                            pJS.interactivity.mouse.pos_x *= pJS.canvas.pxratio;
                            pJS.interactivity.mouse.pos_y *= pJS.canvas.pxratio;
                        }

                        pJS.interactivity.status = 'mousemove';

                    });

                    /* el on onmouseleave */
                    pJS.interactivity.el.addEventListener('mouseleave', function (e) {

                        pJS.interactivity.mouse.pos_x = null;
                        pJS.interactivity.mouse.pos_y = null;
                        pJS.interactivity.status = 'mouseleave';

                    });

                }

                /* on click event */
                if (pJS.interactivity.events.onclick.enable) {

                    pJS.interactivity.el.addEventListener('click', function () {

                        pJS.interactivity.mouse.click_pos_x = pJS.interactivity.mouse.pos_x;
                        pJS.interactivity.mouse.click_pos_y = pJS.interactivity.mouse.pos_y;
                        pJS.interactivity.mouse.click_time = new Date().getTime();

                        if (pJS.interactivity.events.onclick.enable) {

                            switch (pJS.interactivity.events.onclick.mode) {

                                case 'push':
                                    if (pJS.particles.move.enable) {
                                        pJS.fn.modes.pushParticles(pJS.interactivity.modes.push.particles_nb, pJS.interactivity.mouse);
                                    } else {
                                        if (pJS.interactivity.modes.push.particles_nb == 1) {
                                            pJS.fn.modes.pushParticles(pJS.interactivity.modes.push.particles_nb, pJS.interactivity.mouse);
                                        } else if (pJS.interactivity.modes.push.particles_nb > 1) {
                                            pJS.fn.modes.pushParticles(pJS.interactivity.modes.push.particles_nb);
                                        }
                                    }
                                    break;

                                case 'remove':
                                    pJS.fn.modes.removeParticles(pJS.interactivity.modes.remove.particles_nb);
                                    break;

                                case 'bubble':
                                    pJS.tmp.bubble_clicking = true;
                                    break;

                                case 'repulse':
                                    pJS.tmp.repulse_clicking = true;
                                    pJS.tmp.repulse_count = 0;
                                    pJS.tmp.repulse_finish = false;
                                    setTimeout(function () {
                                        pJS.tmp.repulse_clicking = false;
                                    }, pJS.interactivity.modes.repulse.duration * 1000)
                                    break;

                            }

                        }

                    });

                }


            };

            pJS.fn.vendors.densityAutoParticles = function () {

                if (pJS.particles.number.density.enable) {

                    /* calc area */
                    var area = pJS.canvas.el.width * pJS.canvas.el.height / 1000;
                    if (pJS.tmp.retina) {
                        area = area / (pJS.canvas.pxratio * 2);
                    }

                    /* calc number of particles based on density area */
                    var nb_particles = area * pJS.particles.number.value / pJS.particles.number.density.value_area;

                    /* add or remove X particles */
                    var missing_particles = pJS.particles.array.length - nb_particles;
                    if (missing_particles < 0) pJS.fn.modes.pushParticles(Math.abs(missing_particles));
                    else pJS.fn.modes.removeParticles(missing_particles);

                }

            };


            pJS.fn.vendors.checkOverlap = function (p1, position) {
                for (var i = 0; i < pJS.particles.array.length; i++) {
                    var p2 = pJS.particles.array[i];

                    var dx = p1.x - p2.x,
                        dy = p1.y - p2.y,
                        dist = Math.sqrt(dx * dx + dy * dy);

                    if (dist <= p1.radius + p2.radius) {
                        p1.x = position ? position.x : Math.random() * pJS.canvas.w;
                        p1.y = position ? position.y : Math.random() * pJS.canvas.h;
                        pJS.fn.vendors.checkOverlap(p1);
                    }
                }
            };


            pJS.fn.vendors.createSvgImg = function (p) {

                /* set color to svg element */
                var svgXml = pJS.tmp.source_svg,
                    rgbHex = /#([0-9A-F]{3,6})/gi,
                    coloredSvgXml = svgXml.replace(rgbHex, function (m, r, g, b) {
                        if (p.color.rgb) {
                            var color_value = 'rgba(' + p.color.rgb.r + ',' + p.color.rgb.g + ',' + p.color.rgb.b + ',' + p.opacity + ')';
                        } else {
                            var color_value = 'hsla(' + p.color.hsl.h + ',' + p.color.hsl.s + '%,' + p.color.hsl.l + '%,' + p.opacity + ')';
                        }
                        return color_value;
                    });

                /* prepare to create img with colored svg */
                var svg = new Blob([coloredSvgXml], {type: 'image/svg+xml;charset=utf-8'}),
                    DOMURL = window.URL || window.webkitURL || window,
                    url = DOMURL.createObjectURL(svg);

                /* create particle img obj */
                var img = new Image();
                img.addEventListener('load', function () {
                    p.img.obj = img;
                    p.img.loaded = true;
                    DOMURL.revokeObjectURL(url);
                    pJS.tmp.count_svg++;
                });
                img.src = url;

            };


            pJS.fn.vendors.destroypJS = function () {
                cancelAnimationFrame(pJS.fn.drawAnimFrame);
                canvas_el.remove();
                pJSDom = null;
            };


            pJS.fn.vendors.drawShape = function (c, startX, startY, sideLength, sideCountNumerator, sideCountDenominator) {

                // By Programming Thomas - https://programmingthomas.wordpress.com/2013/04/03/n-sided-shapes/
                var sideCount = sideCountNumerator * sideCountDenominator;
                var decimalSides = sideCountNumerator / sideCountDenominator;
                var interiorAngleDegrees = (180 * (decimalSides - 2)) / decimalSides;
                var interiorAngle = Math.PI - Math.PI * interiorAngleDegrees / 180; // convert to radians
                c.save();
                c.beginPath();
                c.translate(startX, startY);
                c.moveTo(0, 0);
                for (var i = 0; i < sideCount; i++) {
                    c.lineTo(sideLength, 0);
                    c.translate(sideLength, 0);
                    c.rotate(interiorAngle);
                }
                //c.stroke();
                c.fill();
                c.restore();

            };

            pJS.fn.vendors.exportImg = function () {
                window.open(pJS.canvas.el.toDataURL('image/png'), '_blank');
            };


            pJS.fn.vendors.loadImg = function (type) {

                pJS.tmp.img_error = undefined;

                if (pJS.particles.shape.image.src != '') {

                    if (type == 'svg') {

                        var xhr = new XMLHttpRequest();
                        xhr.open('GET', pJS.particles.shape.image.src);
                        xhr.onreadystatechange = function (data) {
                            if (xhr.readyState == 4) {
                                if (xhr.status == 200) {
                                    pJS.tmp.source_svg = data.currentTarget.response;
                                    pJS.fn.vendors.checkBeforeDraw();
                                } else {
                                    console.log('Error pJS - Image not found');
                                    pJS.tmp.img_error = true;
                                }
                            }
                        }
                        xhr.send();

                    } else {

                        var img = new Image();
                        img.addEventListener('load', function () {
                            pJS.tmp.img_obj = img;
                            pJS.fn.vendors.checkBeforeDraw();
                        });
                        img.src = pJS.particles.shape.image.src;

                    }

                } else {
                    console.log('Error pJS - No image.src');
                    pJS.tmp.img_error = true;
                }

            };


            pJS.fn.vendors.draw = function () {

                if (pJS.particles.shape.type == 'image') {

                    if (pJS.tmp.img_type == 'svg') {

                        if (pJS.tmp.count_svg >= pJS.particles.number.value) {
                            pJS.fn.particlesDraw();
                            if (!pJS.particles.move.enable) cancelRequestAnimFrame(pJS.fn.drawAnimFrame);
                            else pJS.fn.drawAnimFrame = requestAnimFrame(pJS.fn.vendors.draw);
                        } else {
                            //console.log('still loading...');
                            if (!pJS.tmp.img_error) pJS.fn.drawAnimFrame = requestAnimFrame(pJS.fn.vendors.draw);
                        }

                    } else {

                        if (pJS.tmp.img_obj != undefined) {
                            pJS.fn.particlesDraw();
                            if (!pJS.particles.move.enable) cancelRequestAnimFrame(pJS.fn.drawAnimFrame);
                            else pJS.fn.drawAnimFrame = requestAnimFrame(pJS.fn.vendors.draw);
                        } else {
                            if (!pJS.tmp.img_error) pJS.fn.drawAnimFrame = requestAnimFrame(pJS.fn.vendors.draw);
                        }

                    }

                } else {
                    pJS.fn.particlesDraw();
                    if (!pJS.particles.move.enable) cancelRequestAnimFrame(pJS.fn.drawAnimFrame);
                    else pJS.fn.drawAnimFrame = requestAnimFrame(pJS.fn.vendors.draw);
                }

            };


            pJS.fn.vendors.checkBeforeDraw = function () {

                // if shape is image
                if (pJS.particles.shape.type == 'image') {

                    if (pJS.tmp.img_type == 'svg' && pJS.tmp.source_svg == undefined) {
                        pJS.tmp.checkAnimFrame = requestAnimFrame(check);
                    } else {
                        //console.log('images loaded! cancel check');
                        cancelRequestAnimFrame(pJS.tmp.checkAnimFrame);
                        if (!pJS.tmp.img_error) {
                            pJS.fn.vendors.init();
                            pJS.fn.vendors.draw();
                        }

                    }

                } else {
                    pJS.fn.vendors.init();
                    pJS.fn.vendors.draw();
                }

            };


            pJS.fn.vendors.init = function () {

                /* init canvas + particles */
                pJS.fn.retinaInit();
                pJS.fn.canvasInit();
                pJS.fn.canvasSize();
                pJS.fn.canvasPaint();
                pJS.fn.particlesCreate();
                pJS.fn.vendors.densityAutoParticles();

                /* particles.line_linked - convert hex colors to rgb */
                pJS.particles.line_linked.color_rgb_line = hexToRgb(pJS.particles.line_linked.color);

            };


            pJS.fn.vendors.start = function () {

                if (isInArray('image', pJS.particles.shape.type)) {
                    pJS.tmp.img_type = pJS.particles.shape.image.src.substr(pJS.particles.shape.image.src.length - 3);
                    pJS.fn.vendors.loadImg(pJS.tmp.img_type);
                } else {
                    pJS.fn.vendors.checkBeforeDraw();
                }

            };


            /* ---------- pJS - start ------------ */


            pJS.fn.vendors.eventsListeners();

            pJS.fn.vendors.start();


        };

        /* ---------- global functions - vendors ------------ */

        Object.deepExtend = function (destination, source) {
            for (var property in source) {
                if (source[property] && source[property].constructor &&
                    source[property].constructor === Object) {
                    destination[property] = destination[property] || {};
                    arguments.callee(destination[property], source[property]);
                } else {
                    destination[property] = source[property];
                }
            }
            return destination;
        };

        window.requestAnimFrame = (function () {
            return window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.oRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (callback) {
                    window.setTimeout(callback, 1000 / 60);
                };
        })();

        window.cancelRequestAnimFrame = (function () {
            return window.cancelAnimationFrame ||
                window.webkitCancelRequestAnimationFrame ||
                window.mozCancelRequestAnimationFrame ||
                window.oCancelRequestAnimationFrame ||
                window.msCancelRequestAnimationFrame ||
                clearTimeout
        })();

        function hexToRgb(hex) {
            // By Tim Down - http://stackoverflow.com/a/5624139/3493650
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
            });
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        };

        function clamp(number, min, max) {
            return Math.min(Math.max(number, min), max);
        };

        function isInArray(value, array) {
            return array.indexOf(value) > -1;
        }


        /* ---------- particles.js functions - start ------------ */

        window.pJSDom = [];

        window.particlesJS = function (tag_id, params) {

            //console.log(params);

            /* no string id? so it's object params, and set the id with default id */
            if (typeof (tag_id) != 'string') {
                params = tag_id;
                tag_id = 'particles-js';
            }

            /* no id? set the id to default id */
            if (!tag_id) {
                tag_id = 'particles-js';
            }

            /* pJS elements */
            var pJS_tag = document.getElementById(tag_id),
                pJS_canvas_class = 'particles-js-canvas-el',
                exist_canvas = pJS_tag.getElementsByClassName(pJS_canvas_class);

            /* remove canvas if exists into the pJS target tag */
            if (exist_canvas.length) {
                while (exist_canvas.length > 0) {
                    pJS_tag.removeChild(exist_canvas[0]);
                }
            }

            /* create canvas element */
            var canvas_el = document.createElement('canvas');
            canvas_el.className = pJS_canvas_class;

            /* set size canvas */
            canvas_el.style.width = "100%";
            canvas_el.style.height = "100%";

            /* append canvas */
            var canvas = document.getElementById(tag_id).appendChild(canvas_el);

            /* launch particle.js */
            if (canvas != null) {
                pJSDom.push(new pJS(tag_id, params));
            }

        };

        window.particlesJS.load = function (tag_id, path_config_json, callback) {

            /* load json config */
            var xhr = new XMLHttpRequest();
            xhr.open('GET', path_config_json);
            xhr.onreadystatechange = function (data) {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200) {
                        var params = JSON.parse(data.currentTarget.response);
                        window.particlesJS(tag_id, params);
                        if (callback) callback();
                    } else {
                        console.log('Error pJS - XMLHttpRequest status: ' + xhr.status);
                        console.log('Error pJS - File config not found');
                    }
                }
            };
            xhr.send();

        };

        if ($('#particles-js-process').length > 0) {

            particlesJS('particles-js-process',

                {
                    "particles": {
                        "number": {
                            "value": 70,
                            "density": {
                                "enable": true,
                                "value_area": 900
                            }
                        },
                        "color": {
                            "value": "#a8afcb"
                        },
                        "shape": {
                            "type": "circle",
                            "stroke": {
                                "width": 0,
                                "color": "#a8afcb"
                            },
                            "polygon": {
                                "nb_sides": 5
                            },
                            "image": {
                                "src": "img/github.svg",
                                "width": 100,
                                "height": 100
                            }
                        },
                        "opacity": {
                            "value": 1,
                            "random": false,
                            "anim": {
                                "enable": false,
                                "speed": 0.5,
                                "opacity_min": 0.1,
                                "sync": false
                            }
                        },
                        "size": {
                            "value": 4,
                            "random": true,
                            "anim": {
                                "enable": false,
                                "speed": 40,
                                "size_min": 0.1,
                                "sync": false
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "distance": 150,
                            "color": "#000",
                            "opacity": 0.3,
                            "width": 1
                        },
                        "move": {
                            "enable": true,
                            "speed": 1,
                            "direction": "none",
                            "random": false,
                            "straight": false,
                            "out_mode": "out",
                            "attract": {
                                "enable": false,
                                "rotateX": 600,
                                "rotateY": 1200
                            }
                        }
                    },
                    "interactivity": {
                        "detect_on": "canvas",
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "repulse"
                            },
                            "onclick": {
                                "enable": true,
                                "mode": "push"
                            },
                            "resize": true
                        },
                        "modes": {
                            "grab": {
                                "distance": 400,
                                "line_linked": {
                                    "opacity": 1
                                }
                            },
                            "bubble": {
                                "distance": 400,
                                "size": 40,
                                "duration": 2,
                                "opacity": 8,
                                "speed": 3
                            },
                            "repulse": {
                                "distance": 200
                            },
                            "push": {
                                "particles_nb": 4
                            },
                            "remove": {
                                "particles_nb": 2
                            }
                        }
                    },
                    "retina_detect": true,
                }
            );
        }

        if ($('#particles-js-hero').length > 0) {

            particlesJS('particles-js-hero',

                {
                    "particles": {
                        "number": {
                            "value": 70,
                            "density": {
                                "enable": true,
                                "value_area": 900
                            }
                        },
                        "color": {
                            "value": "#a8afcb"
                        },
                        "shape": {
                            "type": "circle",
                            "stroke": {
                                "width": 0,
                                "color": "#a8afcb"
                            },
                            "polygon": {
                                "nb_sides": 5
                            },
                            "image": {
                                "src": "img/github.svg",
                                "width": 100,
                                "height": 100
                            }
                        },
                        "opacity": {
                            "value": 1,
                            "random": false,
                            "anim": {
                                "enable": false,
                                "speed": 0.5,
                                "opacity_min": 0.1,
                                "sync": false
                            }
                        },
                        "size": {
                            "value": 4,
                            "random": true,
                            "anim": {
                                "enable": false,
                                "speed": 40,
                                "size_min": 0.1,
                                "sync": false
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "distance": 150,
                            "color": "#000",
                            "opacity": 0.3,
                            "width": 1
                        },
                        "move": {
                            "enable": true,
                            "speed": 1,
                            "direction": "none",
                            "random": false,
                            "straight": false,
                            "out_mode": "out",
                            "attract": {
                                "enable": false,
                                "rotateX": 600,
                                "rotateY": 1200
                            }
                        }
                    },
                    "interactivity": {
                        "detect_on": "canvas",
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "repulse"
                            },
                            "onclick": {
                                "enable": true,
                                "mode": "push"
                            },
                            "resize": true
                        },
                        "modes": {
                            "grab": {
                                "distance": 400,
                                "line_linked": {
                                    "opacity": 1
                                }
                            },
                            "bubble": {
                                "distance": 400,
                                "size": 40,
                                "duration": 2,
                                "opacity": 8,
                                "speed": 3
                            },
                            "repulse": {
                                "distance": 200
                            },
                            "push": {
                                "particles_nb": 4
                            },
                            "remove": {
                                "particles_nb": 2
                            }
                        }
                    },
                    "retina_detect": true,
                }
            );
        }

        if ($('#particles-js-hero-2').length > 0) {

            particlesJS('particles-js-hero-2',

                {
                    "particles": {
                        "number": {
                            "value": 70,
                            "density": {
                                "enable": true,
                                "value_area": 900
                            }
                        },
                        "color": {
                            "value": "#a8afcb"
                        },
                        "shape": {
                            "type": "circle",
                            "stroke": {
                                "width": 0,
                                "color": "#a8afcb"
                            },
                            "polygon": {
                                "nb_sides": 5
                            },
                            "image": {
                                "src": "img/github.svg",
                                "width": 100,
                                "height": 100
                            }
                        },
                        "opacity": {
                            "value": 1,
                            "random": false,
                            "anim": {
                                "enable": false,
                                "speed": 0.5,
                                "opacity_min": 0.1,
                                "sync": false
                            }
                        },
                        "size": {
                            "value": 4,
                            "random": true,
                            "anim": {
                                "enable": false,
                                "speed": 40,
                                "size_min": 0.1,
                                "sync": false
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "distance": 150,
                            "color": "#000",
                            "opacity": 0.3,
                            "width": 1
                        },
                        "move": {
                            "enable": true,
                            "speed": 1,
                            "direction": "none",
                            "random": false,
                            "straight": false,
                            "out_mode": "out",
                            "attract": {
                                "enable": false,
                                "rotateX": 600,
                                "rotateY": 1200
                            }
                        }
                    },
                    "interactivity": {
                        "detect_on": "canvas",
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "repulse"
                            },
                            "onclick": {
                                "enable": true,
                                "mode": "push"
                            },
                            "resize": true
                        },
                        "modes": {
                            "grab": {
                                "distance": 400,
                                "line_linked": {
                                    "opacity": 1
                                }
                            },
                            "bubble": {
                                "distance": 400,
                                "size": 40,
                                "duration": 2,
                                "opacity": 8,
                                "speed": 3
                            },
                            "repulse": {
                                "distance": 200
                            },
                            "push": {
                                "particles_nb": 4
                            },
                            "remove": {
                                "particles_nb": 2
                            }
                        }
                    },
                    "retina_detect": true,
                }
            );
        }

        if ($('#particles-js-why').length > 0) {

            particlesJS('particles-js-why',

                {
                    "particles": {
                        "number": {
                            "value": 70,
                            "density": {
                                "enable": true,
                                "value_area": 900
                            }
                        },
                        "color": {
                            "value": "#a8afcb"
                        },
                        "shape": {
                            "type": "circle",
                            "stroke": {
                                "width": 0,
                                "color": "#a8afcb"
                            },
                            "polygon": {
                                "nb_sides": 5
                            },
                            "image": {
                                "src": "img/github.svg",
                                "width": 100,
                                "height": 100
                            }
                        },
                        "opacity": {
                            "value": 1,
                            "random": false,
                            "anim": {
                                "enable": false,
                                "speed": 0.5,
                                "opacity_min": 0.1,
                                "sync": false
                            }
                        },
                        "size": {
                            "value": 4,
                            "random": true,
                            "anim": {
                                "enable": false,
                                "speed": 40,
                                "size_min": 0.1,
                                "sync": false
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "distance": 150,
                            "color": "#000",
                            "opacity": 0.3,
                            "width": 1
                        },
                        "move": {
                            "enable": true,
                            "speed": 1,
                            "direction": "none",
                            "random": false,
                            "straight": false,
                            "out_mode": "out",
                            "attract": {
                                "enable": false,
                                "rotateX": 600,
                                "rotateY": 1200
                            }
                        }
                    },
                    "interactivity": {
                        "detect_on": "canvas",
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "repulse"
                            },
                            "onclick": {
                                "enable": true,
                                "mode": "push"
                            },
                            "resize": true
                        },
                        "modes": {
                            "grab": {
                                "distance": 400,
                                "line_linked": {
                                    "opacity": 1
                                }
                            },
                            "bubble": {
                                "distance": 400,
                                "size": 40,
                                "duration": 2,
                                "opacity": 8,
                                "speed": 3
                            },
                            "repulse": {
                                "distance": 200
                            },
                            "push": {
                                "particles_nb": 4
                            },
                            "remove": {
                                "particles_nb": 2
                            }
                        }
                    },
                    "retina_detect": true,
                }
            );
        }

        //-------------------------Mission Map---------------
        if ($('#map2').length > 0) {
            mapboxgl.accessToken = 'pk.eyJ1IjoiZWtyZWF0aXZlb3JnIiwiYSI6ImNpaHlnZ3VmdjAwYWx3MWtwNWpuZDRmY2EifQ.EVw4opN7LqTx5mAVDeDScg';

            var markers = {
                "type": "FeatureCollection",
                "features": [
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">Ukraine</div><p>There’s a whole list of projects to talk about in Ukraine; from children's work to support for refugees and from business acceleration to work with the homeless.</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin",

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [30.3926089, 50.4021702]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">Tajikistan</div><p>In Tajikistan our teams worked with young people with difficult lives</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin"

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [68.7115413, 38.5614373]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">India</div><p>Four times now we’ve sent teams to Kolkata, where partnering with local charities they’ve contributed to work with street children, providing hygiene, food and fun.</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin"

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [76.9506737, 28.6456707]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">Nepal</div><p>Sending a team to Nepal opened their eyes to the poverty of that poor mountain nation.</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin"

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [85.29111349, 27.7090319]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">Thailand</div><p>In Thailand our team ran a children’s program.</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin"

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [100.4930266, 13.7248946]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">Lebanon</div><p>Fact finding mission, learning from Lebanese refugee programs.</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin"

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [35.4867246, 33.8886459]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">Uganda</div><p>Our team constructed a water distribution system to serve a rural community.</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin"

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [29.5290855, 5.8140103]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">Tanzania</div><p>Our team took part in a school's development program</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin"

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [38.7238701, -5.1684925]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">Rwanda</div><p>Our team helped construct a home for orphanage leavers</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin"

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [29.0695274, -1.9547128]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">South Africa</div><p>Visiting South African prisons running programs for prisoners.</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin"

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [25.1530349, -30.1198392]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">Guatemala</div><p>In just 2 weeks our team decorated a school, built a church and ran an activity camp for local children!</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin",

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [-90.5275799, 14.6263005]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "description": "<div class=\"marker-title\">Kenya</div><p>Our team helped to construct a water collection system at a school.</p>",
                            "id": "krhy93as",
                            "marker-symbol": "pin"

                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [40.6877017, 1.2325347]
                        }
                    },
                ]
            };

            var style = (function () {
                var style = null;
                $.ajax({
                    'async': false,
                    'global': false,
                    'url': '/mission_map_geo/style.json',
                    'dataType': "json",
                    'success': function (data) {
                        style = data;
                    }
                });
                return style;
            })();

            var latitude = 10.856;
            var longitude = 45.867;
            var zoom = 1;

            window.addEventListener('resize', setWindowSize);

            function setWindowSize() {

                if (typeof (window.innerWidth) == 'number') {
                    width = window.innerWidth;
                    resizeMap();
                } else {
                    if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
                        width = document.documentElement.clientWidth;
                        resizeMap();
                    } else {
                        if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
                            width = document.body.clientWidth;
                            resizeMap();
                        }
                    }
                }
            }

            function resizeMap() {
                if (width > 1024) {
                    if (zoom != 1) {
                        zoom = 1;
                        $('#map').remove();
                        $('#map_wrapper').append('<div id="map"></div>');
                        addMap(zoom, latitude, longitude);
                    }
                }

                // if (width < 1024) {
                //     $('#map').remove();
                // }
            }

            var width = $(document).width();

            if (width > 1024) {
                zoom = 1;
                addMap(zoom, latitude, longitude);
            }

            function addMap(zoom, latitude, longitude) {
                var map = new mapboxgl.Map({
                    container: 'map',
                    style: style,
                    center: [latitude, longitude],
                    zoom: zoom
                });

                map.scrollZoom.disable();
                map.doubleClickZoom.disable();
                map.dragRotate.disable();
                map.touchZoomRotate.disable();
                map.dragPan.disable();

                map.on('style.load', function () {
                    // Add marker data as a new GeoJSON source.
                    map.addSource("markers", {
                        "type": "geojson",
                        "data": markers
                    });


                    // Add a layer showing the markers.
                    map.addLayer({
                        "id": "markers",
                        "interactive": true,
                        "type": "symbol",
                        "source": "markers",
                        "layout": {
                            "icon-allow-overlap": true,
                            "icon-image": "{marker-symbol}",
                            "icon-size": 1.45
                        }
                    });
                });

                // When a click event occurs near a marker icon, open a popup at the location of
                // the feature, with description HTML from its properties.
                map.on('click', function (e) {
                    map.featuresAt(e.point, {
                        layer: 'markers',
                        radius: 20,
                        includeGeometry: true
                    }, function (err, features) {
                        if (err || !features.length)
                            return;

                        var feature = features[0];

                        new mapboxgl.Popup()
                            .setLngLat(feature.geometry.coordinates)
                            .setHTML(feature.properties.description)
                            .addTo(map);
                    });
                });

                // Use the same approach as above to indicate that the symbols are clickable
                // by changing the cursor style to 'pointer'.
                map.on('mousemove', function (e) {
                    map.featuresAt(e.point, {layer: 'markers', radius: 20}, function (err, features) {
                        map.getCanvas().style.cursor = (!err && features.length) ? 'pointer' : '';
                    });
                });
            }

            var os = window.navigator.platform;
            var platform = os.indexOf('Win') > -1;
            if (platform == true) {
                $('#map_wrapper').removeClass('hidden-max-md').addClass('hidden-win');
                $('#map-item').removeClass('hidden-max-lg').addClass('show-win')
            }

        }


        if ($('.go-form--custom-att').length > 0) {
            let labelAtt = $('.go-form--custom-att').find('.wpcf7-file').closest('label');

            labelAtt.addClass('att-img')
        }

        $('.show-go-form').on('click', function (e) {
            e.preventDefault();

            $('.go-form-popup-wrapper').removeClass('d-none')
        });

        $('.close-btn').on('click', function (e) {
            e.preventDefault();

            $('.go-form-popup-wrapper').addClass('d-none')
        });

        //-----------------------Common Block Slick Slide-------------

        $('.fw-slider__list').slick({
            autoplay: false,
            autoplaySpeed: 4500,
            infinite: true,
            verticalSwiping: false,
            vertical: false,
            // fade: true,
            speed: 1000,
            dots: true,
            dotsClass: "slide-dots",
            arrows: true,
            responsive: [
                {
                    breakpoint: 1380,
                    settings: {
                        arrows: false
                    }
                }
            ]
        });

        //-----------------------Common Block Project Slide-------------

        $('.projects-slider__list').slick({
            autoplay: false,
            autoplaySpeed: 4500,
            infinite: false,
            verticalSwiping: false,
            vertical: false,
            // fade: true,
            speed: 1000,
            dots: true,
            dotsClass: "slide-dots",
            arrows: true,
            responsive: [
                {
                    breakpoint: 1380,
                    settings: {
                        arrows: false
                    }
                }
            ]
        });

        //-----------------------Common Block - Reviews Slides-------------

        $('.reviews-slider__list').slick({
            autoplay: false,
            autoplaySpeed: 4500,
            infinite: false,
            verticalSwiping: false,
            vertical: false,
            // fade: true,
            speed: 1000,
            dots: true,
            dotsClass: "slide-dots",
            arrows: true,
            responsive: [
                {
                    breakpoint: 1380,
                    settings: {
                        arrows: false
                    }
                }
            ]
        });

        $(".reviews__rang-stars").each(function () {
            $(this).starRating({
                totalStars: 5,
                starShape: 'rounded',
                starSize: 20,
                emptyColor: 'lightgray',
                hoverColor: '#D42B41',
                activeColor: '#D42B41',
                useGradient: false,
                readOnly: true
            });
        });


        //-----------------------Common Block - Accordion-------------


        var accordionItem = $('#accordion .card-header');

        if (accordionItem.length > 0) {
            accordionItem.each(function () {
                $(this).on('click', function () {
                    if ($(this).hasClass('card-header--active')) {
                        $(this).removeClass('card-header--active');
                    } else {
                        $(this).addClass('card-header--active');
                        $('#accordion .card-header').not($(this)).removeClass('card-header--active')
                    }
                })
            })
        }

        //---------Close Modal and Delete btn focus---------

        $('.popup-form').on('hidden.bs.modal', function () {
            $('.clw-action.clw-action--lp').on({
                focus: function () {
                    $(this).blur();
                }
            });
        });

        //--------Fixed scroll for IOS when modal is opened------

        var $window = $(window),
            $body = $("body"),
            $modal = $(".modal.popup-form"),
            scrollDistance = 0;

        $modal.on("show.bs.modal", function () {
            // Get the scroll distance at the time the modal was opened
            scrollDistance = $window.scrollTop();

            // Pull the top of the body up by that amount
            $body.css("top", scrollDistance * -1);
        });

        $modal.on("hidden.bs.modal", function () {
            // Remove the negative top value on the body
            $body.css("top", "");

            // Set the window's scroll position back to what it was before the modal was opened
            $window.scrollTop(scrollDistance);
        });

        /* Technologies list show more button */
        var technologiesList = $('.technologies-list--hide-items')
        if (technologiesList.length) {
            var waitForFinalEvent, hideItems, showItems, resetShowMoreText, hideItemsStart

            waitForFinalEvent = (function () {
                var timers
                timers = {}
                return function (callback, ms, uniqueId) {
                    if (!uniqueId) {
                        uniqueId = 'Don\'t call this twice without a uniqueId'
                    }
                    if (timers[uniqueId]) {
                        clearTimeout(timers[uniqueId])
                    }
                    timers[uniqueId] = setTimeout(callback, ms)
                }
            })()

            hideItems = function (start) {
                showItems()
                technologiesList.find('.technologies-list__item:gt(' + start + ')').not('#technologiesShowMore').addClass('technologies-list__item--hide')
            }
            showItems = function () {
                technologiesList.find('.technologies-list__item').removeClass('technologies-list__item--hide')
            }
            resetShowMoreText = function () {
                technologiesList.find('#technologiesShowMore span').removeClass('technologies-list__title--hide').last().addClass('technologies-list__title--hide')
            }

            $('#technologiesShowMore').on('click', function (e) {
                e.preventDefault()
                var isHidden = $(this).siblings('.technologies-list__item--hide').length
                if (isHidden) {
                    showItems()
                } else {
                    hideItems(hideItemsStart)
                }
                technologiesList.find('#technologiesShowMore span').toggleClass('technologies-list__title--hide')
            })

            if (window.innerWidth < 640) {
                hideItemsStart = 2
                hideItems(hideItemsStart)
                resetShowMoreText()
            } else if (window.innerWidth < 768) {
                hideItemsStart = 3
                hideItems(hideItemsStart)
                resetShowMoreText()
            } else if (window.innerWidth < 992) {
                hideItemsStart = 4
                hideItems(hideItemsStart)
                resetShowMoreText()
            } else {
                hideItemsStart = 5
                hideItems(hideItemsStart)
                resetShowMoreText()
            }
            $(window).resize(function () {
                waitForFinalEvent((function () {
                    if (window.innerWidth < 640) {
                        hideItemsStart = 2
                        hideItems(hideItemsStart)
                        resetShowMoreText()
                    } else if (window.innerWidth < 768) {
                        hideItemsStart = 3
                        hideItems(hideItemsStart)
                        resetShowMoreText()
                    } else if (window.innerWidth < 992) {
                        hideItemsStart = 4
                        hideItems(hideItemsStart)
                        resetShowMoreText()
                    } else {
                        hideItemsStart = 5
                        hideItems(hideItemsStart)
                        resetShowMoreText()
                    }
                }), 200, '')
            })
        }
        /* END Technologies list show more button */

        /* Synchronize list */
        var synchronizeList = $('[data-synchronize-list]')
        if (synchronizeList.length) {
            var synchronizedListId = synchronizeList.data('synchronize-list'),
                synchronizedList = $('#' + synchronizedListId)
            synchronizeList.on('click', 'li', function () {
                var index = $(this).index()
                $(this).siblings('li').removeClass('active')
                $(this).toggleClass('active')
                synchronizedList.find('li:not(:eq(' + index + '))').removeClass('active')
                synchronizedList.find('li').eq(index).toggleClass('active')
                $('html, body').animate({
                    scrollTop: synchronizedList.find('li.active').offset().top
                }, 500)
            })
            synchronizedList.on('click', 'li', function () {
                var index = $(this).index()
                $(this).siblings('li').removeClass('active')
                $(this).toggleClass('active')
                synchronizeList.find('li:not(:eq(' + index + '))').removeClass('active')
                synchronizeList.find('li').eq(index).toggleClass('active')
            })
            $(document).on('click', function (e) {
                if (!$(e.target).closest(synchronizeList).length && !$(e.target).closest(synchronizedList).length) {
                    synchronizeList.find('li').removeClass('active')
                    synchronizedList.find('li').removeClass('active')
                }
            })
        }
        /* END Synchronize list */

        /* Scroll To */
        $('[data-scroll-to]').on('click', function (e) {
            e.preventDefault()
            var scrollToAnchor = $(this).data('scroll-to')
            var scrollTo = $(scrollToAnchor)
            $('html, body').animate({
                scrollTop: scrollTo.offset().top
            }, 500)
        })
        /* END Scroll To */


        /*On the projects page, we check if there is a video without autoplay properties.
          If there is, we run it after scrolling to it. */

        if ($('.content-area-project').length > 0) {

            function playVideo(elem) {
                $(elem).each(function () {
                    if (!$(this).hasClass('play')) {
                        if (isScrolledIntoView($(this))) {
                            $(this).addClass('play')
                            $(this)[0].play()
                        }
                    }
                })
            }

            var videoToPlay = $('.no-autoplay');
            if (videoToPlay.length > 0) {
                $(window).scroll(function () {
                    playVideo('.no-autoplay');
                });
            }
        }

        /* In project page DuctClean we restart the video, after 2 seconds */
        if ($('.site-main.ductclean').length > 0) {
            $("#video-1").bind("ended", function () {
                setTimeout(function () {
                    $("#video-1")[0].play();
                }, 2000);
            });
        }
        /* Show and hide Projects in menu */
        if ($(window).width() > 1200) {
            $('.desktop-menu #main-menu .projects-category >.dropdown-item').hover(function (e) {
                e.preventDefault();

                $('.desktop-menu #main-menu .projects .menu-item').find('.show-projects').removeClass('show-projects');
                $(this).parent().addClass('show-projects');
                let startFirst = $('.desktop-menu #main-menu .projects .dropdown-menu').find('.show-projects');
                let endFirst = startFirst.parent();
                let startSecond = startFirst.find('.dropdown-menu');
                endFirst.height(startSecond.height());
            })

            $('.desktop-menu #main-menu .projects').hover(function (e) {
                e.preventDefault();
                $('.desktop-menu #main-menu .projects .menu-item').find('.show-projects').removeClass('show-projects');
                $('.desktop-menu #main-menu .projects .all-projects.menu-item').addClass('show-projects');
                let startFirst = $('.desktop-menu #main-menu .projects .dropdown-menu').find('.show-projects');
                let endFirst = startFirst.parent();
                let startSecond = startFirst.find('.dropdown-menu');
                endFirst.height(startSecond.height());
            })
        }

        $('#popup-menu #main-menu li.new-menu-item >a').click(function (e) {
            e.preventDefault();
            if ($(this).parent().children(".sub-menu__wrapper").hasClass('show-content')) {
                $(this).parent().children(".sub-menu__wrapper").removeClass('show-content');
            } else {
                $(this).parent().children(".sub-menu__wrapper").addClass('show-content');
            }
        })

        $('#main-menu .projects-category >.dropdown-item').click(function (e) {
            e.preventDefault();
        })

        /* --- Show and hide Projects in menu on mobile --- */

        if ($(window).width() < 1200) {

            $('.popup-menu #main-menu .projects-category >.dropdown-item').click(function (e) {
                e.preventDefault();
                $('.popup-menu #main-menu .projects .menu-item').find('.show-projects').removeClass('show-projects');
                $(this).parent().addClass('show-projects');
                let startFirst = $('.popup-menu #main-menu .projects .dropdown-menu').find('.show-projects');
                let endFirst = startFirst.parent();
                let startSecond = startFirst.find('.dropdown-menu');
                endFirst.height(startSecond.height() + 60);
            })

            $('.popup-menu #main-menu .projects >a').click(function (e) {
                e.preventDefault();
                $('.popup-menu #main-menu .projects .menu-item').find('.show-projects').removeClass('show-projects');
                $('.popup-menu #main-menu .projects .all-projects.menu-item').addClass('show-projects');
                let startFirst = $('.popup-menu #main-menu .projects .dropdown-menu').find('.show-projects');
                let endFirst = startFirst.parent();
                let startSecond = startFirst.find('.dropdown-menu');
                endFirst.height(startSecond.height() + 60);
            })
        }

        $('a').filter(function () {
            return this.hostname && this.hostname !== location.hostname;
        }).attr({target: "_blank", rel: "noopener"});

        //project landing youtube video iframe


        if ($('.section-main-youtube-video').length > 0) {

            var videoURL = $('#ytplayer').prop('src');
            var playVideoURL = videoURL + "?&autoplay=1";
            $('.single-project').on('click', '.yt-wrap', function () {
                setTimeout(function () {
                    $('.video-preview').addClass('play shadow');
                }, 500)
                $('#ytplayer').prop('src', playVideoURL);
            });


            $(document).mouseup(function (e) {
                var preview = $(".video-preview");
                if (!preview.is(e.target)
                    && preview.has(e.target).length === 0
                    && $('.video-preview').hasClass('play')) {
                    preview.removeClass('play shadow')
                    preview.removeClass('visible')
                    $('#ytplayer').prop('src', videoURL);

                }
            });
        }
    });
})(window.jQuery);


