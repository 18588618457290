
window.jQuery(document).ready(function ($) {

  // Use Match Height in Safari browser
  var articleChildH
  var articleParentH
  var articleCounter = 1

  function safariBlockHeight() {
    if ($('body').hasClass('safari')) {
      // $('.blog-post-container').matchHeight()
      $('.blog .article-blog').addClass('col-xl-3')
      if ($('body').hasClass('safari')) {
        // $('.blog .blog-post-container').matchHeight()
        $('.blog .article-blog').addClass('col-xl-3')

        $('.blog .blog-post-container').each(function () {
          $(this).css('height', 'auto')
        })
        $('.blog .blog-post-container').each(function () {
          articleChildH = $(this).outerHeight()
          articleParentH = $(this).closest('.article-blog').outerHeight()

          articleCounter++
          if ( articleChildH < articleParentH ) {
            $(this).outerHeight(articleParentH)
          }
        })
      }
    }
  }

  if ($('body').hasClass('page-template-page-blog' ) || $('body').hasClass('author' ) || $('body').hasClass('category' )  || $('body').hasClass('tag' )) {
    var win = $(window)
    var bottomScroll = $('.empty-scroll')
    var pageLoop = 1
    var postPerPage = 6
    var author = +$('.author-id').text()

    var containerBlog = $('.ajax-inner')
    var iso = ''

    var loadPosts = false
    var noPosts = false
    // Each time the user scrolls
    $('.spinner, .preloader').hide()
    var catId = 0
    var tagID = 0
    win.scroll(function () {
      catId = $('body').attr('data-category-id')
      tagId = $('body').attr('data-tag-id')

      var lastPostHeight = $('.article-blog:last-child').height() + 100
      if ((win.scrollTop() + win.height() + lastPostHeight) > bottomScroll.offset().top) {
        if (!noPosts && !loadPosts) {
          pageLoop++
          loadPosts = true
          var data = {}
          if ( author ) {
            data = {
              'action': 'load_more_posts',
              'author': author,
              'paged':  pageLoop,
              'limit':  postPerPage
            }
          } else {
            data = {
              'action': 'load_more_posts',
              'category_id': catId,
              'tag_id': tagId,
              'paged': pageLoop,
              'limit': postPerPage
            }
          }

          $('.spinner').show()

            $.ajax({
              url: window.load.ajaxurl,
              type: 'post',
              data:  data,
              success: function (data) {
                if (data !== '0') {
                  containerBlog.append(data)

                  // Use Match Height in Safari browser
                  safariBlockHeight()
                } else {
                  noPosts = true
                }
                loadPosts = false
                $('.spinner').hide()
              }
            })
        }
      }
    })
    $(document).on('click', '.category-navigation .list-navigations', function (event) {
      event.preventDefault()

      if ($('.category-navigation .list-navigation')){
        $('.b-active').removeClass('b-active');
      }
      $(this).addClass('b-active')
      noPosts = false
      var catId = $(this).attr('data-category-id')
      pageLoop = 1

        $.ajax({
          url: window.ajaxcategories.ajaxurl,
          type: 'post',
          data: {
            'action': 'ajax_categories',
            'paged': pageLoop,
            'category_id': catId
          },
          success: function (dataResult) {
            $('.container-inner').html(dataResult)
            $('body').attr('data-category-id', catId)

            // Use Match Height in Safari browser
            safariBlockHeight()
          }
        })
    })

    $(window).on('load', function () {
      if ((win.scrollTop() + win.height()) > bottomScroll.offset().top) {
        pageLoop++
        loadPosts = true
        $('.spinner, .preloader').show()

          $.ajax({
            url: window.load.ajaxurl,
            type: 'post',
            data: {
              'action': 'load_more_posts',
              'category_id': catId,
              'tag_id': tagID,
              'paged': pageLoop
            },
            success: function (data) {
              if (data !== '0') {
                containerBlog.append(data)
              } else {
                noPosts = true
              }
              loadPosts = false
              $('.spinner').hide()
              resolve()
            }
          })
      }
    })
  }
})
