window.jQuery(document).ready(function ($) {
  // Mobile menu
  // var topPos
  $('.sandwich').on('click', function () {
    $('.popup-menu').fadeToggle(300)
  })
  // $('.clicked').on('click', function () {
  //   $(this).removeClass('clicked')
  //   $('.popup-menu').fadeOut(300)
  // })
  //   if ( ! $(this).hasClass('clicked')) {
  //     topPos = $(window).scrollTop()
  //     // console.log(topPos)
  //     $('.sandwich').addClass('clicked')
  //     $('#site-nav').addClass('toggled')
  //     $('html, body').addClass('menu-open')
  //     $('.site').addClass('fixed')
  //     $('.site').css('top', topPos)
  //
  //   } else {
  //     $('.sandwich').removeClass('clicked')
  //     $('#site-nav').removeClass('toggled')
  //     $('html, body').removeClass('menu-open')
  //     $('.site').removeClass('fixed')
  //     $(window).scrollTop(topPos)
  //   }
  // })
  //
  // function closeMenu () {
  //   $('#site-nav').removeClass('toggled')
  //   $('html, body').removeClass('menu-open')
  //   $('.site').removeClass('fixed')
  //   $(window).scrollTop(topPos)
  // }
  //
  // $('.close-class').on('click', function () {
  //   $('.sandwich').removeClass('clicked')
  //   $('#site-nav').removeClass('toggled')
  //   $('html, body').removeClass('menu-open')
  //   $('.site').removeClass('fixed')
  //   $(window).scrollTop(topPos)
  // });
  //
  // $(window).on('resize', function () {
  //   if ($(window).width() > 1199) {
  //     closeMenu()
  //   }

  // $('body').live('change',function(){
  // 	if ( $(window).width() < 961 ) {
  // 		$(".sandwich").removeClass('clicked');
  // 		$("#nav").removeClass("open");
  // 		$(".main, .footer ").removeClass("fixed");
  // 		$(window).scrollTop(topPos);
  // 		$("#header").removeClass("menu-open");
  // 	}
  // });
  // $(".scroll-to-link").on("click", function() {
  // 	$(".sandwich").removeClass("clicked");
  // 	$("#nav").removeClass("open");
  // });


  // Mobile menu DE
  $('.sandwich-de').on('click', function () {
    $('#popup-menu').fadeToggle(300)
  })
  $('.popup-wrap').on('click',function () {
    $('#popup-menu').fadeOut(300)
  })
  // $(window).on('resize', function () {
  //   $('#popup-menu').fadeOut(300)
  // })

  //------------Scroll to Sections (Front Page) ------------
  var mainNav = $('.site-header.header-de');
  var popUpmenu = mainNav.find('#popup-menu');

  popUpmenu.on('click', 'a[href*="#"]', function (e) {
    e.preventDefault();
    if (this.hash !== '') {
      if (this.hash !== '' && this.href.replace(this.hash, '') == window.location.href.replace(window.location.hash, '')) {
        var target = $(this.hash);
        var targetTop = target.offset().top;
        var targetTopCorect = targetTop - 35;

        if (target.length && this.hash !== '#') {
          $('body,html').animate({scrollTop: targetTopCorect}, 1500);
        } else {
          if (this.hash !== '#') {
            window.location = $(this).attr('href');
          }
        }
      } else {
        if (this.hash !== '#') {
          window.location = $(this).attr('href');
        }
      }
    }
  });


});
